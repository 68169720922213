import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CupImage from '../../../images/calculator-cup.png'
import PdfPage from './PdfPage'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { StickyContainer, Sticky } from 'react-sticky'
import './styles.scss'

const Calculator = ({
  content,
  entryID,
  button,
  email,
  phone,
  note,
  tooltip,
}) => {
  const [isMounted, setIsMounted] = useState(false)
  const [entryData, setEntryData] = useState(false)
  const [showPdf] = useState(false)
  let sum = array => array.reduce((a, b) => a + b)

  let numbers = {}

  useEffect(() => {
    if (!isMounted) {
      try {
        fetch(`/wp-json/dhsv/v1/gf/calculatorEntry/?id=${entryID}`, {
          method: 'GET',
        })
          .then(response => {
            return response.json()
          })
          .then(entryData => {
            console.log(entryData) //eslint-disable-line
            const {
              workingDays,
              cupsPerDay,
              businessType,
              rentMachine,
            } = entryData
            setEntryData({
              workingDays,
              cupsPerDay,
              businessType,
              rentMachine,
            })
          })
      } catch (error) {
        console.log(error) // eslint-disable-line
      }

      setIsMounted(true)
    }
  })

  if (entryData) {
    // internal entryData
    const distibutionBySize = {
      small: 0.3,
      medium: 0.6,
      large: 0.1,
    }

    const costs = {
      coffee: [0.175, 0.163],
      equipment: [0.136, 0.122],
      milk: [0.044, 0.044],
      machine: {
        perCup: 0.27,
        perDay: 8,
        perMonth: 243,
        perYear: 2916,
      },
    }

    numbers.goodsCostPerCup = parseFloat(
      (
        (costs.coffee[1] * 1000 +
          costs.equipment[1] * 1000 +
          costs.milk[1] * 1000) /
        1000
      ).toFixed(2)
    )
    // console.log('goods cost per cup', numbers.goodsCostPerCup) // eslint-disable-line

    const costPerCup = parseFloat(
      (
        (numbers.goodsCostPerCup * 100 +
          (entryData.rentMachine ? costs.machine.perCup : 0) * 100) /
        100
      ).toFixed(2)
    )
    // console.log('cost per cup', costPerCup) // eslint-disable-line

    // const costPerDay = parseInt(
    //   (numbers.goodsCostPerCup * 100 * entryData.cupsPerDay +
    //     costs.machine.perDay * 100) /
    //     100
    // )
    // console.log('cost per day', costPerDay) // eslint-disable-line

    // const costPerMonth = parseInt(
    //   (numbers.goodsCostPerCup * 100 * entryData.cupsPerDay * 30 +
    //     costs.machine.perMonth * 100) /
    //     100
    // )
    // console.log('cost per month', costPerMonth) // eslint-disable-line

    // const costPerYear = parseInt(
    //   (numbers.goodsCostPerCup * 100 * entryData.cupsPerDay * 30 * 12 +
    //     costs.machine.perYear * 100) /
    //     100
    // )
    // console.log('cost per year', costPerYear) // eslint-disable-line

    const businessTypes = [
      {
        id: 'bakery',
        name: 'Bäckerei',
        priceModifier: '-0.1',
      },
      {
        id: 'filling_station',
        name: 'Tankstelle',
        priceModifier: '0',
      },
      {
        id: 'convenience_store',
        name: 'Convenience Store',
        priceModifier: '-0.1',
      },
      {
        id: 'autohof',
        name: 'Autohof',
        priceModifier: '+0.1',
      },
    ]

    const coffeeTypes = [
      {
        id: 'caffe_crema',
        name: 'Caffè Crema',
        distribution: 0.62,
        basePricesBySize: { small: 1.7, medium: 1.9, large: 2.1 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
      {
        id: 'caffe_latte',
        name: 'Caffè Latte',
        distribution: 0.07,
        basePricesBySize: { small: 2.2, medium: 2.4, large: 2.6 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
      {
        id: 'cappuccino',
        name: 'Cappuccino',
        distribution: 0.12,
        basePricesBySize: { small: 2, medium: 2.2, large: 2.4 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
      {
        id: 'latte_macchiato',
        name: 'Latte Macchiato',
        distribution: 0.1,
        basePricesBySize: { small: 2.2, medium: 2.4, large: 2.6 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
      {
        id: 'espresso',
        name: 'Espresso',
        distribution: 0.04,
        basePricesBySize: { small: 1.7, medium: 1.9, large: 1.9 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
      {
        id: 'choco',
        name: 'Choco',
        distribution: 0.04,
        basePricesBySize: { small: 1.9, medium: 2.1, large: 2.3 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
      {
        id: 'chocopresso',
        name: 'Chocopresso',
        distribution: 0.01,
        basePricesBySize: { small: 2, medium: 2.2, large: 2.4 },
        priceBySize: { small: 0, medium: 0, large: 0 },
        recommendedPrices: {},
        averagePrices: {},
      },
    ]

    coffeeTypes.map(el => {
      businessTypes.map(bt => {
        el.recommendedPrices[bt.id] = {
          small: parseFloat(
            (
              (el.basePricesBySize.small * 100 + eval(bt.priceModifier) * 100) /
              100
            ).toFixed(2)
          ),
          medium: parseFloat(
            (
              (el.basePricesBySize.medium * 100 +
                eval(bt.priceModifier) * 100) /
              100
            ).toFixed(2)
          ),
          large: parseFloat(
            (
              (el.basePricesBySize.large * 100 + eval(bt.priceModifier) * 100) /
              100
            ).toFixed(2)
          ),
        }

        el.averagePrices[bt.id] = {
          small: parseFloat(
            (
              el.recommendedPrices[bt.id].small *
              100 *
              el.distribution *
              100 /
              10000
            ).toFixed(2)
          ),
          medium: parseFloat(
            (
              el.recommendedPrices[bt.id].medium *
              100 *
              el.distribution *
              100 /
              10000
            ).toFixed(2)
          ),
          large: parseFloat(
            (
              el.recommendedPrices[bt.id].large *
              100 *
              el.distribution *
              100 /
              10000
            ).toFixed(2)
          ),
        }
      })

      el.priceBySize = {
        small: parseFloat(
          el.averagePrices[entryData.businessType].small.toFixed(2)
        ),
        medium: parseFloat(
          el.averagePrices[entryData.businessType].medium.toFixed(2)
        ),
        large: parseFloat(
          el.averagePrices[entryData.businessType].large.toFixed(2)
        ),
      }
    })

    const cupPricesBySize = {
      small: parseFloat(
        (sum(coffeeTypes.map(el => el.priceBySize.small * 100)) / 100).toFixed(
          2
        )
      ),
      medium: parseFloat(
        (sum(coffeeTypes.map(el => el.priceBySize.medium * 100)) / 100).toFixed(
          2
        )
      ),
      large: parseFloat(
        (sum(coffeeTypes.map(el => el.priceBySize.large * 100)) / 100).toFixed(
          2
        )
      ),
    }
    // console.log('cupPricesBySize', cupPricesBySize) // eslint-disable-line

    numbers.priceBrutto = parseFloat(
      sum(
        Object.entries(cupPricesBySize).map(item => {
          return parseFloat(
            (item[1] * 100 * distibutionBySize[item[0]] * 100 / 10000).toFixed(
              2
            )
          )
        })
      ).toFixed(2)
    )
    // console.log('brutto price', numbers.priceBrutto) // eslint-disable-line

    const priceNetto = parseFloat((numbers.priceBrutto / 1.19).toFixed(2))
    // console.log('netto price', priceNetto) // eslint-disable-line

    numbers.taxAmount = parseFloat(
      (numbers.priceBrutto - priceNetto).toFixed(2)
    )
    // console.log('taxAmount', numbers.taxAmount) // eslint-disable-line

    numbers.revenuePerCup = parseFloat((priceNetto - costPerCup).toFixed(2))
    // console.log('revenue per cup', numbers.revenuePerCup) // eslint-disable-line

    // let nettoPerDay = numbers.revenuePerCup * entryData.cupsPerDay
    // console.log('nettoPerDay', nettoPerDay) // eslint-disable-line

    numbers.revenuePerDay = parseFloat(
      (numbers.revenuePerCup * entryData.cupsPerDay).toFixed(2)
    )
    // console.log('revenue per day', numbers.revenuePerDay) // eslint-disable-line

    const workingDaysPerMonth = parseInt(
      Math.ceil(30 * (entryData.workingDays / 7).toFixed(2))
    )
    // console.log('workingDaysPerMonth', workingDaysPerMonth) // eslint-disable-line

    numbers.revenuePerMonth = parseInt(
      numbers.revenuePerDay * workingDaysPerMonth
    )
    // console.log('revenue per month', numbers.revenuePerMonth) // eslint-disable-line

    numbers.revenuePerYear = parseInt(numbers.revenuePerMonth * 12)
    // console.log('revenue per year', numbers.revenuePerYear) // eslint-disable-line

    const marges = {
      withMachine: parseInt(
        (numbers.revenuePerCup / priceNetto * 100).toFixed(0)
      ),
      basic: parseInt(
        (
          (numbers.revenuePerCup + costs.machine.perCup) /
          priceNetto *
          100
        ).toFixed(0)
      ),
    }
    // console.log('marges', marges) // eslint-disable-line

    // used for previewving the result pdf
    if (showPdf) {
      return (
        <PDFViewer
          style={{
            marginTop: '60px',
            width: '60%',
            height: '100vh',
          }}
        >
          <PdfPage
            entryData={entryData}
            marges={marges}
            numbers={numbers}
            costs={costs}
            note={note}
            content={content}
            email={email}
            phone={phone.label}
          />
        </PDFViewer>
      )
    }

    return (
      <React.Fragment>
        <div className="dhsv-calculation-result d-flex flex-wrap flex-lg-nowrap">
          <StickyContainer className="dhsv-calculation-result__content">
            <Sticky>
              {({ style }) => (
                <div
                  className="dhsv-calculation-result__content-wrapper"
                  style={style}
                >
                  <div className="dhsv-calculation-result__back">
                    <div className="dhsv_vc_staging_button">
                      <a className="staging_button" href={document.referrer} />
                      <span className="title">Zuruck</span>
                    </div>
                  </div>
                  <p
                    className="dhsv-calculation-result__description"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                  <div className="dhsv-calculation-result__buttons">
                    <PDFDownloadLink
                      document={
                        <PdfPage
                          entryData={entryData}
                          marges={marges}
                          numbers={numbers}
                          costs={costs}
                          note={note}
                          content={content}
                          email={email}
                          phone={phone.label}
                        />
                      }
                      fileName="Calculation.pdf"
                      className="btn btn-outline-dark btn-icon"
                    >
                      {({ loading }) => {
                        return loading ? (
                          'Loading document...'
                        ) : (
                          <React.Fragment>
                            PDF herunterladen
                            <i className="btr bt-arrow-down" />
                          </React.Fragment>
                        )
                      }}
                    </PDFDownloadLink>
                    {button.url && (
                      <a href={button.url} className="btn btn-dark text-center">
                        {button.title}
                      </a>
                    )}
                  </div>
                  <div className="dhsv-calculation-result__links">
                    <p>
                      Noch Fragen? Dann wenden Sie sich an unser Kaffee-Team:
                    </p>
                    {email && (
                      <a href={`mailto:${email}`}>
                        <i className="btr bt-envelope" />
                        {email}
                      </a>
                    )}
                    {phone.esc && (
                      <a href={`tel:${phone.esc}`}>
                        <i className="btr bt-phone" />
                        {phone.label}
                      </a>
                    )}
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
          <div className="dhsv-calculation-result__infographic">
            <div
              className="dhsv-calculation-result__tooltip"
              dangerouslySetInnerHTML={{ __html: tooltip }}
            />
            <p>Ertrag bei {entryData.cupsPerDay} Bechern pro Tag:</p>
            <ul className="revenues">
              <li className="revenues__item revenues__item--per-day">
                <div className="amount">
                  ~{numbers.revenuePerDay.toLocaleString('de')}€
                </div>
                <div className="label">/Tag</div>
              </li>
              <li className="revenues__item revenues__item--per-month">
                <div className="amount">
                  ~{numbers.revenuePerMonth.toLocaleString('de')}€
                </div>
                <div className="label">/Monat</div>
              </li>
              <li className="revenues__item revenues__item--per-year">
                <div className="amount">
                  ~{numbers.revenuePerYear.toLocaleString('de')}€
                </div>
                <div className="label">/Jahr</div>
              </li>
            </ul>

            <div className="cup-scheme">
              <p>Durchschnittlicher Ertrag pro Becher:</p>

              <div className="cup">
                <img src={CupImage} alt="cup image" />

                <div className="divider divider--revenue">Konzeptertrag</div>
                <div className="divider divider--cost">Konzeptkosten</div>

                <div className="price">
                  <div className="amount">
                    {numbers.priceBrutto.toLocaleString('de')}€
                  </div>
                  <div className="label">Verkaufspreis</div>
                </div>

                <div className="revenue">
                  <div className="amount">
                    {numbers.revenuePerCup.toLocaleString('de')}€
                  </div>
                  <div className="label">Ertrag pro Becher</div>
                </div>

                <div className="costs">
                  <div className="costs__item costs__item--goods">
                    <div className="label">Wareneinsatz</div>
                    <div className="amount">
                      {numbers.goodsCostPerCup.toLocaleString('de')}€
                    </div>
                  </div>
                  <div className="costs__item costs__item--machine">
                    <div className="label">Maschinenkosten</div>
                    <div className="amount">
                      {entryData.rentMachine
                        ? costs.machine.perCup.toLocaleString('de')
                        : 0}€
                    </div>
                  </div>
                  <div className="costs__item costs__item--tax">
                    <div className="label">Umsatzsteuer</div>
                    <div className="amount">
                      {numbers.taxAmount.toLocaleString('de')}€
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="marge">
              <p>Margen:</p>
              <div className="marge__items">
                <div className="marge__item marge__item--basic">
                  <div className="marge__item-content">
                    <div className="amount">
                      <span className="prefix">ca.</span>
                      80%
                    </div>
                    <div className="label">Bruttomarge</div>
                    <div className="clarification">(bei reinem Warenbezug)</div>
                  </div>
                </div>
                <div className="marge__item marge__item--machine">
                  <div className="marge__item-content">
                    <div className="amount">
                      <span className="prefix">ca.</span>
                      60%
                    </div>
                    <div className="label">Bruttomarge</div>
                    <div className="clarification">(inkl. Maschine)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {note && (
          <p className="dhsv-calculation-result__note font-italic">{note}</p>
        )}
      </React.Fragment>
    )
  } else {
    return 'Loading...'
  }
}

Calculator.propTypes = {
  isMD: PropTypes.bool,
  content: PropTypes.string,
  entryID: PropTypes.string,
  button: PropTypes.object,
  email: PropTypes.string,
  phone: PropTypes.object,
  note: PropTypes.string,
  tooltip: PropTypes.string,
}

export default Calculator
