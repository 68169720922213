import React from 'react'
import PropTypes from 'prop-types'
import ReactCountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const rand = (Math.random() * (99999999 - 10000000 + 1)) << 0

function findAncestor(el, cls) {
  while ((el = el.parentNode) && el.className.indexOf(cls) < 0);
  return el
}

class CountUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      didViewCountUp: false,
    }
    this.countUp = React.createRef()
    this.onVisibilityChange = this.onVisibilityChange.bind(this)
  }

  onVisibilityChange(isVisible) {
    if (isVisible) {
      const parent = findAncestor(
        this.countUp.containerRef.current,
        'dhsv_countup'
      )
      parent.classList.add('start')
      this.setState({ didViewCountUp: true })
    }
  }

  render() {
    const { number, prefix, suffix } = this.props
    const { didViewCountUp } = this.state
    return (
      <VisibilitySensor onChange={this.onVisibilityChange} delayedCall>
        <ReactCountUp
          className="number"
          decimal=","
          separator="."
          duration={4}
          start={0}
          end={didViewCountUp ? number : 0}
          prefix={prefix}
          suffix={suffix}
          id={`dhsv_number_${rand}`}
          ref={countUp => {
            this.countUp = countUp
          }}
          useEasing={true}
          useGrouping={true}
        />
      </VisibilitySensor>
    )
  }
}

CountUp.propTypes = {
  number: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
}

export default CountUp
