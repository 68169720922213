import $ from 'jquery'

$(document).ready(() => {
  dhsv_vc_expandable_content()
})

$(window).resize(() => {
  dhsv_vc_expandable_content()
})

function dhsv_vc_expandable_content() {
  $('.dhsv_vc_expandable_content').each((i, el) => {
    // Number of lines to be displayed above the fold
    const previewLinesNumber = parseInt(el.dataset.preview_lines_number)

    const innerElement = el.querySelector('.js-inner')

    // Calculate the height of the preview
    const previewHeight = parseInt(
      previewLinesNumber *
        parseFloat(window.getComputedStyle(innerElement).lineHeight)
    )

    // Reset inner element height
    innerElement.style.height = ''
    el.classList.remove('is-expanded')
    // Get the height of unfolded content
    const fullHeight = parseInt(window.getComputedStyle(innerElement).height)

    // Show controls if unfolded height is more then the preview
    if (fullHeight >= previewHeight) {
      innerElement.style.height = previewHeight + 'px'
      el.classList.add('is-expandable')
    } else {
      innerElement.style.height = ''
      el.classList.remove('is-expandable')
    }

    const trigger = el.querySelector('.js-trigger')
    if (!trigger.dataset.isListening) {
      trigger.dataset.isListening = true
      trigger.addEventListener('click', () => {
        $(innerElement).animate(
          {
            height:
              (el.classList.contains('is-expanded')
                ? previewHeight
                : fullHeight) + 'px',
          },
          400
        )
        el.classList.toggle('is-expanded')
      })
    }
  })
}
