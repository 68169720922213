import React from 'react'
import PropTypes from 'prop-types'

class DhsvSocialItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data } = this.props
    return (
      <div className={'blog-item social'}>
        <div className="inner" style={{ backgroundColor: data.bgcolor }}>
          <div className="meta">
            <h3>{data.title}</h3>
            <div className="more">
              <a
                href={data.link.url}
                rel="noopener"
                target="_blank"
                className={data.plattform}
              >
                {data.link.title}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DhsvSocialItem.propTypes = {
  data: PropTypes.object,
}

export default DhsvSocialItem
