import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import l10n from '../../../shared/l10n'

const { common: { readMore } } = l10n

class Teaser extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { featured, post } = this.props

    return (
      <a
        href={post.link}
        className={`dhsv_post ${featured ? '-featured' : ''}`}
      >
        <div className="__meta">
          <div className="__categories">
            {post.kategorien.map((cat, i) => (
              <div className="post__category tag" key={i}>
                {cat}
              </div>
            ))}
          </div>
          <div className="__date">{post.date}</div>
        </div>
        {post.image && (
          <div
            className="__thumbnail"
            style={{ backgroundImage: `url(${post.image})` }}
          />
        )}
        <div className="__title">{post.title}</div>
        <div className="btn btn-link btn-icon">
          {readMore}
          <i className="btb bt-arrow-right" />
        </div>
      </a>
    )
  }
}

Teaser.propTypes = {
  featured: PropTypes.bool,
  post: PropTypes.object,
}

export default Teaser
