import React from 'react'
import PropTypes from 'prop-types'
import DhsvBlogTaxListing from './taxListing'

class DhsvBlogFilterBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stickyBar: false, searchTerm: '' }

    this.ref = React.createRef()
    this.onScroll = this.onScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  onScroll() {
    /* const rect = this.ref.current.getBoundingClientRect()

    if (rect.top - 58 < rect.height * -1) {
      this.setState({ stickyBar: true })
    } else {
      this.setState({ stickyBar: false })
    } */

    if (window.pageYOffset > 47) {
      this.setState({ stickyBar: true })
    } else {
      this.setState({ stickyBar: false })
    }
  }

  render() {
    const {
      strings,
      tax,
      handleSearchTermChange,
      currentID,
      logo,
      isMD,
    } = this.props
    const { stickyBar, searchTerm } = this.state
    return (
      <React.Fragment>
        <div className="dhsv_blog_filterbar" ref={this.ref}>
          <div className="search_wrapper">
            <input
              value={searchTerm}
              defaultValue={strings.search ? strings.search : ''}
              onChange={e => {
                handleSearchTermChange(e)
                this.setState({ searchTerm: e.target.value.toLowerCase() })
              }}
              placeholder={strings.searchPlaceholder}
            />
          </div>
          {tax.map((taxItem, index) => (
            <DhsvBlogTaxListing
              isMD={isMD}
              key={index}
              type={taxItem.type}
              title={taxItem.title}
              currentID={currentID}
            />
          ))}
        </div>
        <div className="dhsv_fullbox">
          <div
            className={`dhsv_blog_filterbar -sticky ${stickyBar ? 'show' : ''}`}
          >
            <div className="container">
              <a className="logo_wrapper" href={'/'}>
                <img src={logo} alt="" />
              </a>
              <div className="search_wrapper">
                <input
                  value={searchTerm}
                  defaultValue={strings.search ? strings.search : ''}
                  onChange={e => {
                    handleSearchTermChange(e)
                    this.setState({ searchTerm: e.target.value.toLowerCase() })
                  }}
                  placeholder={strings.searchPlaceholder}
                />
              </div>
              {tax.map((taxItem, index) => (
                <DhsvBlogTaxListing
                  isMD={true}
                  key={index}
                  type={taxItem.type}
                  title={taxItem.title}
                  currentID={currentID}
                />
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

DhsvBlogFilterBar.propTypes = {
  isMD: PropTypes.bool,
  strings: PropTypes.object,
  handleSearchTermChange: PropTypes.func,
  currentID: PropTypes.number,
  tax: PropTypes.array,
  logo: PropTypes.string,
}

export default DhsvBlogFilterBar
