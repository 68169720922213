import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Chip = ({ label, onClick, all }) => (
  <span className={`dhsv_chip ${all ? 'all' : ''}`} onClick={onClick}>
    {label}
  </span>
)

Chip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  all: PropTypes.bool,
}

export default Chip
