import stickElement from '../components/stickit'
import 'jquery-drilldown'
import { getCookie, setCookie } from '../util/cookie'

export default {
  fixedHeader() {
    $('.banner--fixed')[
      ($(window).scrollTop() > 0 ? 'add' : 'remove') + 'Class'
    ]('set-sticky')
  },
  gfFieldsScript() {
    // Gravity Form: hide label when input is in focus
    // Handling Gravity Form input labels
    $(document).ready(function() {
      $('body')
        .on('focus', 'input, textarea', e => {
          let $container = $(e.target).closest('.gfield')
          if ($(e.target).closest('.ginput_complex').length > 0) {
            $container = $(e.target).closest('span')
          }
          $container.addClass('is-focused')
        })
        .on('blur', 'input, textarea', e => {
          if (!$(e.target).val()) {
            let $container = $(e.target).closest('.gfield')
            if ($(e.target).closest('.ginput_complex').length > 0) {
              $container = $(e.target).closest('span')
            }
            $container.removeClass('is-focused')
          }
        })
    })

    $(document).on('gform_post_render', () => {
      $('.gform_wrapper select:not(.default_select)').SumoSelect()
    })
  },
  init() {
    this.gfFieldsScript()

    function smooth_scroll(link, e) {
      if (e) e.preventDefault()
      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }
      if (hash && $(hash).length) {
        $('html, body').animate(
          {
            scrollTop: $(hash).offset().top - 120,
          },
          600
        )
        history.replaceState(null, null, hash)
      }
      $(window).trigger('hashchange')
    }

    function setMobileClass() {
      if ($(window).outerWidth() <= 991) {
        $('body').addClass('mobile')
      } else {
        $('body').removeClass('mobile')
      }
    }

    $(window).load(function() {
      /* eslint-disable */
      $(
        'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"])'
      ).on('click', function(e) {
        smooth_scroll($(this).attr('href'), e)
      })
      /* eslint-enable */
      if (window.location.hash) {
        smooth_scroll(window.location.hash)
      }

      stickElement('.banner:not(.banner--fixed) .navbar')
      stickElement('.page-anchors')
      // stickElement('.contact-person')
      // stickElement('#page-contents')

      // Gravity Form: Put the Submit button into 'gform_body' container
      // $('.gform_column')
      //   .last()
      //   .append($('.gform_footer'))

      // gfFieldsScript()

      $('.drilldown').drilldown()

      $('.drilldown .menu-item-has-children > a').on('click', function(e) {
        e.preventDefault()
      })

      // Calls the selectBoxIt method for default HTML select box
      $('select:not(.default_select)').SumoSelect()

      // $('.gform_wrapper').addClass('loaded')
    })

    // Gravity Form: Keep the Submit button into 'gform_body' container after form submitting
    // $(document).bind('gform_post_render', function() {
    //   $('.gform_column')
    //     .last()
    //     .append($('.gform_footer'))
    //
    //   gfFieldsScript()
    // })

    /* function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })
        if (!$(this).attr('data-vc-stretch-content')) {
          // let padding =
          //   (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
          // $(this).css({
          //   'padding-left': padding,
          //   'padding-right': padding,
          // })
        }
      })
    }

    resizeFullWidthRow() */

    $(window).on('resize', function() {
      //resizeFullWidthRow()
      setMobileClass()
    })

    // Scroll to top
    // defining elements
    function getScrollWindow() {
      var e = document.documentElement,
        o = document.body,
        t = e.scrollTop || (o && o.scrollTop) || 0
      return (t -= e.clientTop)
    }

    var toTop = $('.to-top')

    $(document).ready(() => {
      this.fixedHeader()
      // Listening for click event
      $('.to-top').on('click', function(e) {
        e.preventDefault()
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          600
        )
      })
      setMobileClass()
    })

    $(window).on('scroll', () => {
      this.fixedHeader()

      // Detecting when to show the button
      if (
        getScrollWindow() > $(window).outerHeight() / 2 &&
        !toTop.hasClass('visible')
      ) {
        toTop.addClass('visible')
      } else if (
        getScrollWindow() <= $(window).outerHeight() / 2 &&
        toTop.hasClass('visible')
      ) {
        toTop.removeClass('visible')
      }
    })

    $('.banner [data-toggle="collapse"]').on('click', function() {
      $(this)
        .siblings('[data-toggle="collapse"][aria-expanded="true"]')
        .trigger('click')
    })

    // Menu dropdown on hover
    $('.nav-item.dropdown').on('mouseenter', function() {
      const $this = $(this)
      const $menu = $this.find('> .dropdown-menu')
      if (!$menu.hasClass('show') && !$('body').hasClass('mobile')) {
        $menu.addClass('show')
      }
    })
    $('.nav-item .nav-item:not(.dropdown)').on('mouseenter', function() {
      const $this = $(this)
      const $menu = $this.parent().find('.nav-item.dropdown > .dropdown-menu')
      if ($menu.hasClass('show') && !$('body').hasClass('mobile')) {
        $menu.removeClass('show')
      }
    })
    $('.navbar-nav > .nav-item.dropdown').on('mouseleave', function() {
      const $this = $(this)
      const $menu = $this.find('> .dropdown-menu')
      if ($menu.hasClass('show') && !$('body').hasClass('mobile')) {
        $menu.removeClass('show')
      }
    })
    $(
      '.navbar-nav > .nav-item.dropdown > .dropdown-menu > .nav-item.dropdown'
    ).on('mouseleave', function() {
      const $this = $(this)
      const $menu = $this.find('> .dropdown-menu')
      if ($menu.hasClass('show') && !$('body').hasClass('mobile')) {
        $menu.removeClass('show')
      }
    })
    $('.dropdown-menu').on('mouseleave', function() {
      const $this = $(this)
      $this.removeClass('show')

      $this.find('.dropdown-menu').removeClass('show')
    })
    $('.dropdown-toggle').on('click tap', function() {
      const $this = $(this)
      $this.next().toggleClass('show')
    })

    // DHSV
    init_lngBox()
    init_videobox()
    init_mediabox()
    init_tobaccoDisclaimer()
    init_popupDisclaimer()
    init_toggleSearchbar()
    init_footerDropdowns()
    init_mobileMenu()
    init_SectionScrollDown()

    function init_lngBox() {
      $('.topbar__languages .lng__head').on('click tap', function() {
        var e = $(this)
        var parent = e.parent()

        if (parent.hasClass('active')) {
          parent.removeClass('active')
        } else {
          parent.addClass('active')
        }
      })

      $(document).on('mouseup touchend', function(e) {
        const $head = $('.topbar__languages .lng__head')
        if (!$head.is(e.target) && $head.has(e.target).length === 0) {
          $head.parent().removeClass('active')
        }
      })

      $('.topbar__languages .lng__body').on('mouseleave', function() {
        const $this = $(this)
        $this.parent().removeClass('active')
      })
    }

    function init_videobox() {
      const videobox = $('.videobox')
      const videoframe = videobox.find('.videowrapper .videocon iframe')

      /* eslint-disable */
      // Disable Related Videos
      if (window.hideYTActivated) return
      let onYouTubeIframeAPIReadyCallbacks = []
      const videowrappers = document.querySelectorAll('.videowrapper')
      const videowrappersArray = Array.from(videowrappers)
      videowrappersArray.forEach(playerWrap => {
        let playerFrame = playerWrap.querySelector('iframe')
        let tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        let firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        let onPlayerStateChange = function(event) {
          if (event.data == YT.PlayerState.ENDED) {
            playerWrap.classList.add('ended')
          } else if (event.data == YT.PlayerState.PAUSED) {
            playerWrap.classList.add('paused')
          } else if (event.data == YT.PlayerState.PLAYING) {
            playerWrap.classList.remove('ended')
            playerWrap.classList.remove('paused')
          }
        }
        let player
        onYouTubeIframeAPIReadyCallbacks.push(function() {
          player = new YT.Player(playerFrame, {
            events: { onStateChange: onPlayerStateChange },
          })
        })
        playerWrap.addEventListener('click', function() {
          let playerState = player.getPlayerState()
          if (playerState == YT.PlayerState.ENDED) {
            player.seekTo(0)
          } else if (playerState == YT.PlayerState.PAUSED) {
            player.playVideo()
          }
        })
      })
      window.hideYTActivated = true
      /* eslint-enable */

      $(document).on('mouseup touchend', function(e) {
        const videocon = $('.videobox.open .videowrapper .videocon')
        if (
          (!videocon.is(e.target) &&
            videocon.has(e.target).length === 0 &&
            videocon.find('iframe')[0]) ||
          videocon.find('span').is(e.target)
        ) {
          videobox.removeClass('open')
          videocon
            .find('iframe')[0]
            .contentWindow.postMessage(
              '{"event":"command","func":"pauseVideo","args":""}',
              '*'
            )
        }
      })

      $(document).on('click tap', '[data-videoid]', function() {
        let $this = $(this)
        if ($this.data('videoid') !== videoframe.data('oldid')) {
          videobox.addClass('isLoading')
          videoframe.attr(
            'src',
            '//www.youtube-nocookie.com/embed/' +
              $this.data('videoid') +
              '?enablejsapi=1&amp;rel=0&amp;showinfo=0'
          )
          videoframe.data('oldid', $this.data('videoid'))
        }

        videobox.addClass('open')
      })

      $(videoframe).on('load', function() {
        videobox.removeClass('isLoading')
        videoframe[0].contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          '*'
        )
      })
    }

    function init_mediabox() {
      const modalbox = $('.fullscreen-modal')
      const modalbox_inner = modalbox.find('.inner')

      $('.dhsv_vc_media .fullscreen').on('click tap', function() {
        let $this = $(this)
        modalbox.fadeIn().addClass('open')
        modalbox_inner.css(
          'background-image',
          'url(' + $this.data('imagesrc') + ')'
        )
      })

      modalbox_inner.find('.icon').on('click tap', function() {
        modalbox_inner.fadeOut().removeClass('open')
      })
    }

    function init_tobaccoDisclaimer() {
      const $disclaimer = $('.disclaimer.-tobacco')
      if ($disclaimer.length > 0) {
        if (getCookie('tobaccoDisclaimerClosed')) {
          $disclaimer.remove()
        } else {
          $disclaimer.removeClass('hidden')

          $disclaimer.find('button').on('click tap', function() {
            $disclaimer.remove()
            setCookie('tobaccoDisclaimerClosed', 1)
          })
        }
      }
    }

    function init_popupDisclaimer() {
      const $disclaimer = $('.disclaimer.-popup')
      if ($disclaimer.length > 0) {
        if (getCookie('popupDisclaimerClosed')) {
          $disclaimer.remove()
        } else {
          $disclaimer.removeClass('hidden')

          $disclaimer.find('button').on('click tap', function() {
            $disclaimer.remove()
            setCookie('popupDisclaimerClosed', 1)
          })
        }
      }
    }

    function init_toggleSearchbar() {
      const $searchbar = $('#searchbar')
      $('.searchbar-toggler').on('click tap', function() {
        $searchbar.toggleClass('open')
      })

      $searchbar.find('i').on('click tap', function() {
        $searchbar.removeClass('open')
      })
    }

    function init_footerDropdowns() {
      $(
        '.footer__main .footer__menu > li.menu-item-has-children > ul > li.menu-item-has-children > a'
      ).on('click tap', function(e) {
        e.preventDefault()
        const $this = $(this)
        //const $li = $this.parent()
        var $menu = $this.next()

        if ($this.hasClass('active')) {
          $menu.animate(
            {
              height: 0,
            },
            400
          )
          $this.removeClass('active')
        } else {
          $menu.animate(
            {
              height: $menu.get(0).scrollHeight,
            },
            400,
            function() {
              $menu.height('auto')
            }
          )
          $this.addClass('active')
        }
      })
    }

    function init_mobileMenu() {
      $('#mobile-menu-toggler').on('click tap', function(e) {
        e.preventDefault()
        $('.mobile-navigation').toggleClass('open-mobile')
      })

      $('.mobile-menu li a').on('click tap', function() {
        $('.mobile-navigation').removeClass('open-mobile')
      })
    }

    function init_SectionScrollDown() {
      $('.vc_section').each(function() {
        if ($(this).hasClass('show-slide-arrow')) {
          $(this).append('<div class="slide-arrow-down"></div>')
        }
      })

      $('body').on('click', '.slide-arrow-down', function() {
        $('body,html').animate(
          {
            scrollTop: $(this)
              .parent()
              .height(),
          },
          800
        )
      })
    }

    // usercentrics settings modal
    $('a').on('click', function() {
      if (
        $(this)
          .attr('href')
          .includes('#uc-corner-modal-show')
      ) {
        window.UC_UI.showSecondLayer()
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

$(document).ready(function() {
  //check all images on the page
  $('.tab-pane .teaser-thumbnail img ').each(function() {
    var img = new Image()
    img.onload = function() {
      //console.log($(this).attr('src') + ' - done!')
      equal_height()
    }
    img.src = $(this).attr('src')
  })

  function equal_height() {
    var dhsv_vc_event_box_height = 0
    var dhsv_vc_event_box_height_elm = 0
    $('.dhsv_vc_services_list .tab-content .tab-pane').each(function() {
      dhsv_vc_event_box_height_elm = $(
        '.dhsv_vc_services_list .tab-content .tab-pane'
      ).height()
      if (dhsv_vc_event_box_height_elm > dhsv_vc_event_box_height) {
        dhsv_vc_event_box_height = dhsv_vc_event_box_height_elm
      }
    })
    $('.dhsv_vc_services_list .tab-content .tab-pane').each(function() {
      $('.dhsv_vc_services_list .tab-content .tab-pane').css(
        'height',
        dhsv_vc_event_box_height + 60 + 'px'
      )
    })
  }
})
