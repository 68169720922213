import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import l10n from '../../../shared/l10n'

const { common: { download } } = l10n

class Teaser extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { post } = this.props

    return (
      <div className={`dhsv_post`}>
        {post.image && (
          <div
            className="__thumbnail"
            style={{ backgroundImage: `url(${post.image})` }}
          />
        )}
        <div className="__meta">
          <div className="__categories">
            {post.kategorien.length > 0 &&
              post.kategorien.map((cat, i) => (
                <div className="__category tag" key={i}>
                  {cat}
                </div>
              ))}
          </div>
        </div>
        <div className="__title">{post.title}</div>
        <div className="__type">
          {post.type} {post.size}
        </div>
        <a download href={post.image} className="btn btn-link btn-icon">
          {download}
          <i className="btb bt-download" />
        </a>
      </div>
    )
  }
}

Teaser.propTypes = {
  post: PropTypes.object,
}

export default Teaser
