import React from 'react'
import PropTypes from 'prop-types'

const SvgIndicator = props => {
  return (
    <svg
      width="0"
      height="0"
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="60" r="50" />
      <circle
        cx="60"
        cy="60"
        r="50"
        style={{ animationDelay: -props.i / 5 + 's' }}
      />
    </svg>
  )
}

SvgIndicator.propTypes = {
  i: PropTypes.number,
}

class LoadingIndicator extends React.Component {
  render() {
    return (
      <div className="loader-wrapper" key={0}>
        <div className="item">
          <div className="inner">
            <SvgIndicator i={2} />
          </div>
        </div>
        <div className="item">
          <div className="inner">
            <SvgIndicator i={1} />
          </div>
        </div>
        <div className="item">
          <div className="inner">
            <SvgIndicator i={0} />
          </div>
        </div>
      </div>
    )
  }
}

export default LoadingIndicator
