import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import './styles.scss'
import DhsvBlogShareIcons from '../DhsvBlog/share'
import l10n from '../../shared/l10n'

const { common: { readMore } } = l10n

class MagazinSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { slides } = this.props

    const slickSettings = {
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      dots: false,
      infinite: false,
      speed: 750,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplay: false,
      autoplaySpeed: 10000,
      customPaging: () => <span />,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            autoplay: true,
          },
        },
        {
          breakpoint: 575,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
          },
        },
      ],
    }

    return (
      <div className={`dhsv_magazin_slider`}>
        <Slider {...slickSettings}>
          {slides.map((slide, i) => (
            <div className={'blog-item'} key={i}>
              <div className="inner">
                <DhsvBlogShareIcons links={slide.sharelinks} />
                <div
                  className="image"
                  style={{
                    backgroundImage: slide.image
                      ? 'url(' + slide.image + ')'
                      : null,
                  }}
                />
                <div className="meta">
                  <div className="wrapper">
                    <ul>
                      {slide.categories
                        ? slide.categories.map((cat, key) => (
                            <li key={key}>
                              <a href={cat.link}>{cat.name}</a>
                            </li>
                          ))
                        : null}
                    </ul>
                    <h3>
                      <a
                        href={slide.link}
                        dangerouslySetInnerHTML={{
                          __html: slide.title,
                        }}
                      />
                    </h3>
                    <div className="more">
                      <a href={slide.link}>{readMore}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

MagazinSlider.propTypes = {
  slides: PropTypes.array.isRequired,
}

export default MagazinSlider
