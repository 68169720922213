import $ from 'jquery'

$(document).ready(dhsv_vc_page_anchors())

function dhsv_vc_page_anchors() {
  var $pageAnchors = $('.page-anchors')
  var handleAnchorSwitch = $anchor => {
    $pageAnchors.find('.js-current-amchor').text($anchor.text())
  }

  $('body').scrollspy({
    target: '#page-anchors',
    offset: 120,
  })

  $(window)
    .on('hashchange', function() {
      var hash = location.hash
      $pageAnchors.find('a').each(function() {
        var that = $(this)
        that[that.attr('href') === hash ? 'addClass' : 'removeClass']('active')
      })
    })
    .on('activate.bs.scrollspy', function(e, obj) {
      var target = (obj && obj.relatedTarget) || null
      if (target === null) {
        target = document.activeElement
      }
      handleAnchorSwitch($('a[href="' + target + '"]'))
    })

  $('.js-mobile-anchor-toggle').on('click', function() {
    $(this)
      .closest('.page-anchors')
      .find('.page-anchors__list')
      .slideToggle()
  })
}
