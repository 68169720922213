import React from 'react'
import PropTypes from 'prop-types'

class DhsvBlogShareIcons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLinks: false,
    }
  }

  render() {
    const { links } = this.props
    const { showLinks } = this.state

    return (
      <div className="sharelinks">
        <div
          className="-trigger"
          onClick={() =>
            this.setState(prevState => ({ showLinks: !prevState.showLinks }))
          }
        >
          <i className="btb bt-share bt-3x" />
        </div>
        <div className={`-ctn ${showLinks ? 'show' : ''}`}>
          {links &&
            Object.keys(links).map((key, index) => (
              <a
                key={index}
                className={`-${key}`}
                href={links[key].url}
                target="_blank"
                rel="noopener"
                alt={links[key].title}
              >
                <i className={`fab fab-${key}`} />
              </a>
            ))}
        </div>
      </div>
    )
  }
}

DhsvBlogShareIcons.propTypes = {
  links: PropTypes.object,
}

DhsvBlogShareIcons.defaultProps = {
  links: {},
}

export default DhsvBlogShareIcons
