import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Teaser = ({ data }) => (
  <div
    className="-teaser"
    style={{ backgroundImage: 'url(' + data.image + ')' }}
  >
    <div className="row">
      <div className="col-12 col-lg-6 ctncon">
        <div className="meta">
          {data.tag && (
            <p>
              <span className="tag">{data.tag}</span>
            </p>
          )}
        </div>
        <p className="h2">„{data.teasertitle}“</p>
        {data.link && (
          <a
            className="button link"
            href={data.link.url}
            dangerouslySetInnerHTML={{
              __html: data.link.title + '<i class="btb bt-arrow-right"></i>',
            }}
          />
        )}
      </div>
    </div>
  </div>
)

Teaser.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Teaser
