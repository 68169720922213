import $ from 'jquery'

$(document).ready(function() {
  $('.js-hotspot').on('click', function() {
    $('.js-services-list-popups')
      .find(`.js-popup#${$(this).data('target')}`)
      .addClass('is-active')
      .siblings()
      .removeClass('is-active')
  })

  $('.js-popup .js-close').on('click', function() {
    $(this)
      .closest('.js-popup')
      .removeClass('is-active')
  })
  // $('.dhsv_vc_contact_teaser').each(function() {
  //   const $hotspot = $(this).find('.hotspot')
  //   $hotspot.on('click', function() {
  //     const dataContact = $(this).data('hotspot')
  //     if (!$(this).hasClass('active')) {
  //       $(this)
  //         .addClass('active')
  //         .siblings('.active')
  //         .removeClass('active')
  //       $(dataContact)
  //         .siblings('.active')
  //         .removeClass('active')
  //         .hide()
  //         .end()
  //         .addClass('active')
  //         .hide()
  //         .fadeIn(500)
  //     }
  //   })
  // })
  // var $currentTab = $('a[data-toggle="tab"].active')
  // $($currentTab.attr('href'))
  //   .find('#interactive-map #' + $currentTab.data('id') + ' .map')
  //   .addClass('active')
  // countUp($($currentTab.attr('href')).find('.countries2-facts'))
  //
  // $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
  //   var mapId = $(e.target).data('id')
  //   var $targetContent = $($(e.target).attr('href'))
  //   countUp($targetContent)
  //   $targetContent
  //     .find('#interactive-map #' + mapId + ' .map')
  //     .addClass('active')
  // })
})
