import $ from 'jquery'

$(document).ready(dhsv_vc_media())

function dhsv_vc_media() {
  /*
  $('.fullscreen').on('click tap', function() {
    $(this)
      .closest('.dhsv_vc_media ')
      .find('.fullscreen-modal')
      .fadeIn()
      .addClass('open')
  })

  $('.icon').on('click tap', function() {
    $(this)
      .closest('.dhsv_vc_media ')
      .find('.fullscreen-modal')
      .fadeOut()
      .removeClass('open')
  })
  */
}
