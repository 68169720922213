import React from 'react'
import PropTypes from 'prop-types'

class DhsvExternItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data } = this.props

    return (
      <div className={`blog-item extern -${data.bgcolor}`}>
        <div className="inner">
          <div
            className="image"
            style={{
              backgroundImage: data.icon ? 'url(' + data.icon + ')' : null,
            }}
          />
          <div className="meta">
            <h3>{data.title}</h3>
            <a
              href={data.link.url}
              rel={data.link.target ? 'noopener' : ''}
              target={data.link.target ? '_blank' : ''}
            >
              {data.link.title}
              <i className="btb bt-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

DhsvExternItem.propTypes = {
  row: PropTypes.string,
  data: PropTypes.object,
}

export default DhsvExternItem
