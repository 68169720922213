import React from 'react'
import PropTypes from 'prop-types'
import Slider from './Slider'
import './styles.scss'

class ImageCtnSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentIndex: 0 }

    this.slider = React.createRef()
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(currentIndex) {
    this.setState({ currentIndex })
  }

  render() {
    const { size, showlink, slides, isMD } = this.props
    const { currentIndex } = this.state

    return (
      <div className={`dhsv_image_ctn_slider ${size}`}>
        <div className="row">
          <div className="col">
            <Slider
              size={size}
              showlink={showlink}
              slides={slides}
              handleChange={this.handleChange}
              ref={slider => {
                this.slider = slider
              }}
            />
          </div>
          {!isMD && (
            <div className="col-auto listcon">
              <ul>
                {slides.map((slide, i) => (
                  <li
                    onClick={() => {
                      this.slider.flkty.select(i)
                    }}
                    key={i}
                    className={currentIndex === i ? 'active' : ''}
                  >
                    {slide.shortTitle ? slide.shortTitle : slide.title}
                  </li>
                ))}
              </ul>
              <div className="flickity-arrows">
                <div
                  className={`flickity-arrow -left ${
                    currentIndex === 0 ? '-disabled' : ''
                  }`}
                  onClick={() => {
                    this.slider.flkty.previous()
                  }}
                />
                <div
                  className={`flickity-arrow -right ${
                    currentIndex === slides.length - 1 ? '-disabled' : ''
                  }`}
                  onClick={() => {
                    this.slider.flkty.next()
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

ImageCtnSlider.propTypes = {
  isMD: PropTypes.bool,
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  showlink: PropTypes.bool.isRequired,
  slides: PropTypes.array.isRequired,
}

export default ImageCtnSlider
