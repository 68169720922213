import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import './styles.scss'

class PostsCustomSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { layout, slides, isMD } = this.props

    //eslint-disable-next-line
    const ArrowPrev = ({ currentSlide, slideCount, ...props }) => {
      return <div {...props} />
    }

    //eslint-disable-next-line
    const ArrowNext = ({ currentSlide, slideCount, ...props }) => {
      return <div {...props} />
    }

    const slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      infinite: true,
      speed: 750,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplay: true,
      autoplaySpeed: 10000,
      prevArrow: <ArrowPrev />,
      nextArrow: <ArrowNext />,
      customPaging: () => <span />,
    }

    return (
      <div className={`dhsv_posts_custom_slider ${layout}`}>
        <Slider ref={c => (this.slider = c)} {...slickSettings}>
          {slides.map((slide, i) => (
            <div
              key={i}
              style={{ backgroundImage: 'url(' + slide.image + ')' }}
            >
              {isMD &&
                layout === 'circle' && (
                  <div
                    className="-mobile"
                    style={{ backgroundImage: 'url(' + slide.image + ')' }}
                  />
                )}
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-6 ctncon">
                    <div className="meta">
                      {slide.tag && (
                        <p>
                          <span className="tag">{slide.tag}</span>
                        </p>
                      )}
                    </div>
                    <p className={layout === 'circle' ? 'h1' : 'h2'}>
                      {slide.title}
                    </p>
                    <p className="h3">{slide.subline}</p>
                    {slide.link && (
                      <a
                        className="btn btn-dark btn-icon"
                        href={slide.link.url}
                        dangerouslySetInnerHTML={{
                          __html:
                            slide.link.title +
                            '<i class="btb bt-arrow-right"></i>',
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

PostsCustomSlider.propTypes = {
  isMD: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
}

export default PostsCustomSlider
