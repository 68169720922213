import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import './styles.scss'
import l10n from '../../shared/l10n'

const { common: { showMore } } = l10n

const AkademieTraining = ({ programs }) => {
  const [activeTraining, setActiveTraining] = useState(null)
  const [activeModule, setActiveModule] = useState(null)
  const ref = useRef(null)

  const closeModal = () => {
    setActiveModule(null)
  }

  const closeButton = <i onClick={closeModal} className="icon ion-close" />

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={`dhsv_akademie_training`}>
      <div className="row">
        {programs.map((program, i) => (
          <div key={i} className="col-12 col-md-6 col-lg-4">
            <div className="dhsv_akademie_training_modules">
              <img src={program.icon} />
              <p className="big red">{program.title}</p>
              {program.modules.map((module, i) => (
                <div key={i} className="dhsv_akademie_training_module">
                  <p>
                    <span>{module.title}: </span>
                    {module.teaser}
                  </p>
                  <button
                    onClick={() => {
                      setActiveTraining(program.slug)
                      setActiveModule(module)
                    }}
                    className="btn btn-link btn-icon"
                  >
                    {showMore}
                    <i className="btb bt-arrow-right" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {activeModule !== null && (
        <Modal
          ref={ref}
          training={activeTraining}
          module={activeModule}
          closeButton={closeButton}
        />
      )}
    </div>
  )
}

AkademieTraining.propTypes = {
  programs: PropTypes.array,
  isMD: PropTypes.bool,
}

export default AkademieTraining
