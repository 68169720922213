import React from 'react'
import PropTypes from 'prop-types'
import TestimonialBG from './testimonial_bg.svg'
import DhsvBlogShareIcons from './share'

class DhsvBlogItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { index, item, itemStyle, readMore, row } = this.props

    //console.log(item) //eslint-disable-line

    const svg = ['magazin_2_spalten_left', 'magazin_2_spalten_right'].includes(
      row
    )
      ? atob(TestimonialBG.replace('data:image/svg+xml;base64,', ''))
      : false

    return item ? (
      <div className={'blog-item'} style={itemStyle ? itemStyle[index] : null}>
        <div className="inner">
          <DhsvBlogShareIcons links={item.sharelinks} />
          <div
            className="image"
            style={{
              backgroundImage: item.post_thumbnail
                ? 'url(' + item.post_thumbnail.large + ')'
                : null,
            }}
          />
          <div className="meta">
            {svg ? (
              <div className="bg" dangerouslySetInnerHTML={{ __html: svg }} />
            ) : null}
            <div className="wrapper">
              <ul>
                {item.categories
                  ? item.categories.map((cat, key) => (
                      <li key={key}>
                        <a href={cat.link}>{cat.name}</a>
                      </li>
                    ))
                  : null}
                <li className="date" hidden>
                  <a>{item.date_format}</a>
                </li>
              </ul>
              <h3>
                <a
                  href={item.link}
                  dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                />
              </h3>
              <div className="excerpt">{item.excerpt}</div>
              <div className="more">
                <a href={item.link}>
                  {item.fields['title_teaser_button']
                    ? item.fields['title_teaser_button']
                    : readMore}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="">Loading</div>
    )
  }
}

DhsvBlogItem.propTypes = {
  isMD: PropTypes.bool,
  index: PropTypes.number,
  item: PropTypes.object,
  itemStyle: PropTypes.array,
  readMore: PropTypes.string,
  row: PropTypes.string,
}

export default DhsvBlogItem
