import React from 'react'
import PropTypes from 'prop-types'
import Block from './Block'
import './styles.scss'

class Subsidiaries extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeBlock: 0 }
  }

  handleChange(currentIndex) {
    this.setState({ currentIndex })
  }

  render() {
    const { posts } = this.props
    const { activeBlock } = this.state

    return (
      <div className={`dhsv_subsidiaries`}>
        <div className="row">
          <div className="col-auto listcon">
            <ul>
              {posts.map((post, i) => (
                <li
                  onClick={() => this.setState({ activeBlock: i })}
                  key={i}
                  className={activeBlock === i ? 'active' : ''}
                >
                  {post.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="col blockcon">
            <Block post={posts[activeBlock]} />
          </div>
        </div>
      </div>
    )
  }
}

Subsidiaries.propTypes = {
  isMD: PropTypes.bool,
  posts: PropTypes.array.isRequired,
}

export default Subsidiaries
