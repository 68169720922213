import React from 'react'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import l10n from '../../shared/l10n'

const { common: { discovernow } } = l10n

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stopRender: false }

    this.setSliderHeight = this.setSliderHeight.bind(this)
  }

  componentDidMount() {
    const classThis = this

    this.flkty.on('ready', () => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'))
      }, 1000)
    })

    this.flkty.on('change', i => {
      this.setState({ stopRender: true })
      this.props.handleChange(i)
    })

    this.flkty.on('settle', () => {
      this.setState({ stopRender: false })
    })

    this.flkty.on('resize', function() {
      setTimeout(classThis.setSliderHeight, 0, this)
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.stopRender) {
      return false
    } else {
      return true
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent('resize'))
  }

  setSliderHeight(self) {
    // EQUAL HEIGHTS, "setGallerySize: false" is required
    self.slides.forEach(function(slide) {
      slide.cells[0].element.style.height = 0
      slide.cells[0].element.firstChild.style.height = 'auto'
    })

    // firstChild -> we need a relative(!) wrapper in slide item
    const heights = self.slides.map(slide => {
      return slide.cells[0].element.firstChild.scrollHeight
    })

    const max = Math.max.apply(Math, heights)
    self.slides.forEach(function(slide) {
      slide.cells[0].element.style.height = max + 'px'
      slide.cells[0].element.firstChild.style.height = max + 'px'
    })
    self.slider.style.height = max + 'px'
  }

  render() {
    const { size, /*showlink, */ slides } = this.props

    const flickityOptions = {
      cellSelector: '.slide',
      draggable: slides.length > 1 ? true : false,
      pageDots: false,
      prevNextButtons: true,
      contain: true,
      freeScroll: false,
      autoPlay: false,
      pauseAutoPlayOnHover: false,
      wrapAround: false,
      setGallerySize: false,
    }

    return (
      <Flickity
        className={'flickity'}
        elementType={'div'}
        options={flickityOptions}
        disableImagesLoaded={false}
        reloadOnUpdate={false}
        flickityRef={c => (this.flkty = c)}
      >
        {slides.map((slide, i) => (
          <div className="slide" key={i}>
            <div className="-wrapper">
              <div
                className="imgcon"
                style={{ backgroundImage: 'url(' + slide.image + ')' }}
              >
                <div style={{ position: 'relative' }} />
              </div>
              <div className="row ctncon">
                <div className="col">
                  <p>
                    <strong>{slide.title}</strong>
                  </p>
                  <p>{slide.text}</p>
                  <a href={slide.link} className="btn btn-dark btn-icon">
                    {discovernow}!<i className="btb bt-arrow-right" />
                  </a>
                </div>
                {size === '-large' && (
                  <div className="col-auto d-none d-md-block">
                    {slide.circleNumber &&
                      slide.circleTitle && (
                        <div className="crcl">
                          <span>{slide.circleNumber}</span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: slide.circleTitle,
                            }}
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </Flickity>
    )
  }
}

Slider.propTypes = {
  size: PropTypes.string.isRequired,
  showlink: PropTypes.bool.isRequired,
  slides: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default Slider
