import React, { Component } from 'react'
import PropTypes from 'prop-types'
import equals from 'ramda/es/equals'
import './styles.scss'
import l10n from '../../../shared/l10n'

const { common: { accept } } = l10n

class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = { open: false, values: [] }

    this.wrapperRef = React.createRef()

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    if (this.props.value.length) {
      this.setState({ values: this.props.value })
    }
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps) {
    if (this.props.value.length < prevProps.value.length) {
      this.setState({ values: this.props.value })
    }
  }

  handleClickOutside(e) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ open: false, values: this.props.value })
    }
  }

  render() {
    const { disabled = false, label, onChange, options, value } = this.props
    const { open, values } = this.state
    return (
      <div
        className={`dhsv_filter_dropdown ${value.length ? 'active' : ''} ${
          open ? 'open' : ''
        }`}
        ref={this.wrapperRef}
      >
        <button
          disabled={disabled}
          onClick={() => this.setState({ open: !open })}
        >
          {label}
        </button>
        {open && (
          <div className="dropdown">
            <ul>
              {options.map((option, key) => {
                const checked = !!values.find(value => equals(value, option))
                return (
                  <li key={key}>
                    <label className={`checkbox ${checked ? 'has-value' : ''}`}>
                      <input
                        type="checkbox"
                        defaultChecked={checked}
                        onClick={() =>
                          this.setState({
                            values: checked
                              ? values.filter(value => !equals(value, option))
                              : [...values, option],
                          })
                        }
                      />
                      {option}
                    </label>
                  </li>
                )
              })}
            </ul>
            <button
              onClick={() => {
                onChange(values)
                this.setState({ open: false })
              }}
            >
              {accept}
            </button>
          </div>
        )}
      </div>
    )
  }
}

Dropdown.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.array,
}

Dropdown.defaultProps = {
  options: [],
  value: [],
}

export default Dropdown
