import React from 'react'
import PropTypes from 'prop-types'
import reducer, { loadTax } from './state'
import l10n from '../../shared/l10n'

const { overviews, common: { trending } } = l10n

class DhsvBlogTaxListing extends React.Component {
  constructor(props) {
    super(props)
    this.state = reducer()
    this.dispatch = this.dispatch.bind(this)
    this.loadTax = this.loadTax.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
  }

  componentDidMount() {
    const { type } = this.props
    this.loadTax({ type })
  }

  dispatch(action) {
    if (typeof action === 'function') {
      action(this.dispatch, () => this.state)
    } else {
      this.setState(prevState => reducer(prevState, action))
    }
  }

  loadTax() {
    const { type } = this.props
    this.dispatch(loadTax({ type }))
  }

  changeSelect(e) {
    if (e.target.value === 'all') {
      window.location = window.location.origin + '/' + overviews.magazine
    } else {
      window.location = e.target.value
    }
  }

  render() {
    const { data } = this.state
    const { title, type, currentID, isMD } = this.props
    data.sort((a, b) => {
      return a.count > b.count ? -1 : 1
    })
    return data.length > 0 ? (
      <div className="tax_wrapper">
        <span className="title">
          {trending} {title}:
        </span>
        {isMD ? (
          <div className="select_wrapper">
            <select className="default_select" onChange={this.changeSelect}>
              <option value="all">{title}</option>
              {data.slice(0, 7).map((item, index) => (
                <option
                  value={item.link}
                  selected={item.id === currentID}
                  key={index}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <ul className={`-${type}`}>
            {data.slice(0, 7).map((item, index) => (
              <li
                key={index}
                className={item.id === currentID ? 'current-item' : null}
              >
                <a
                  href={
                    item.id === currentID ? '/' + overviews.magazine : item.link
                  }
                >
                  {item.name}
                  {item.id === currentID ? (
                    <i className="icon ion-close" />
                  ) : null}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    ) : null
  }
}

DhsvBlogTaxListing.propTypes = {
  isMD: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  currentID: PropTypes.number,
}

export default DhsvBlogTaxListing
