import React from 'react'
import PropTypes from 'prop-types'
import DhsvBlogItem from './blogItem'
import DhsvSocialItem from './socialItem'
import DhsvTestimonialItem from './testimonialItem'
import DhsvExternItem from './externItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingIndicator from './loading'

class DhsvBlogPostGrid extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent('scroll'))
  }

  render() {
    const {
      readMore,
      loadMore,
      hasMore,
      posts,
      startGrid,
      teaser,
      isMD,
      current,
    } = this.props

    return (
      <div>
        {startGrid.length > 0 && current.type === 'blog' ? (
          <div className="start-grid">
            {startGrid.map((item, index) => {
              const row = isMD ? 'magazin_3_spalten' : item.row

              return (
                <div className={row} key={index}>
                  {['post', 'selected_post'].includes(item.type) &&
                  item.data ? (
                    <DhsvBlogItem
                      index={index}
                      item={item.data}
                      readMore={readMore}
                      row={row}
                    />
                  ) : item.type === 'facebookfollow' ? (
                    <DhsvSocialItem data={item.data} />
                  ) : item.type === 'linkedin' ? (
                    <DhsvSocialItem data={item.data} />
                  ) : item.type === 'testimonial' ? (
                    <DhsvTestimonialItem row={row} data={item.data} />
                  ) : item.type === 'extern' ? (
                    <DhsvExternItem row={row} data={item.data} />
                  ) : (
                    <div className="blog-item" />
                  )}
                </div>
              )
            })}
          </div>
        ) : null}
        {!teaser && posts ? (
          <InfiniteScroll
            className="post-grid"
            dataLength={posts.length}
            next={loadMore}
            hasMore={hasMore}
            endMessage={''}
            loader={<LoadingIndicator />}
            useWindow={true}
            scrollThreshold={0.65}
          >
            {posts.map((item, index) => (
              <DhsvBlogItem
                key={index}
                index={index}
                item={item}
                readMore={readMore}
              />
            ))}
          </InfiniteScroll>
        ) : null}
      </div>
    )
  }
}

DhsvBlogPostGrid.propTypes = {
  isMD: PropTypes.bool,
  teaser: PropTypes.bool,
  posts: PropTypes.array,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  readMore: PropTypes.string,
  startGrid: PropTypes.array,
  current: PropTypes.object,
}

export default DhsvBlogPostGrid
