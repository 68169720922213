import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import AnimateHeight from 'react-animate-height'
import './styles.scss'

class HeroSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      slideIndex: 0,
    }
  }

  render() {
    const { slider: { slides, circle }, isMD } = this.props
    const { slideIndex } = this.state

    //eslint-disable-next-line
    const ArrowPrev = ({ currentSlide, slideCount, ...props }) => {
      return <div {...props} />
    }

    //eslint-disable-next-line
    const ArrowNext = ({ currentSlide, slideCount, ...props }) => {
      return <div {...props} />
    }

    const slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: true,
      fade: true,
      speed: 500,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplay: true,
      autoplaySpeed: 10000,
      prevArrow: <ArrowPrev />,
      nextArrow: <ArrowNext />,
      beforeChange: (current, next) => {
        setTimeout(() => {
          if (!this.props.isMD) {
            this.setState({ slideIndex: next })
          }
        }, 10)
      },
      responsive: [
        {
          breakpoint: 991,
          settings: {
            arrows: false,
            speed: 500,
            swipe: false,
            autoplay: false,
          },
        },
      ],
    }

    const slickSettings2 = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      pauseOnHover: false,
      pauseOnFocus: false,
      customPaging: () => <span />,
      afterChange: current => {
        setTimeout(() => {
          this.slider.slickGoTo(current)
        }, 10)
      },
    }

    return (
      <div className="hero_slider">
        <Slider
          ref={c => (this.slider = c)}
          className="-desktop"
          {...slickSettings}
        >
          {slides.map((slide, i) => (
            <div
              key={i}
              style={{ backgroundImage: 'url(' + slide.image + ')' }}
            />
          ))}
        </Slider>
        {!isMD ? (
          <div className="slider--nav">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <ul>
                    {slides.map((slide, i) => (
                      <li key={i} onClick={() => this.slider.slickGoTo(i)}>
                        <AnimateHeight
                          className={`ctn ${i === slideIndex ? 'active' : ''}`}
                          height={i === slideIndex ? 'auto' : 50}
                          delay={0}
                          duration={250}
                        >
                          <div
                            style={{ height: i === slideIndex ? '' : 0 }}
                            className="ctn--head"
                          >
                            {slide.titleBold}
                          </div>
                          <div className="ctn--body">
                            <div className="meta">
                              {slide.tag && (
                                <p>
                                  <span className="tag">{slide.tag}</span>
                                </p>
                              )}
                            </div>
                            <h1
                              className={
                                slide.title.length + slide.titleBold.length > 85
                                  ? 'shrinken'
                                  : ''
                              }
                            >
                              {slide.title}
                              <br />
                              <strong>{slide.titleBold}</strong>
                            </h1>
                            {slide.link && (
                              <a
                                className="btn btn-dark btn-icon"
                                href={slide.link.url}
                                target={slide.link.target ? '_blank' : ''}
                                rel={slide.link.target ? 'noopener' : ''}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    slide.link.title +
                                    '<i class="btb bt-arrow-right"></i>',
                                }}
                              />
                            )}
                          </div>
                        </AnimateHeight>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Slider {...slickSettings2}>
            {slides.map((slide, i) => (
              <div key={i}>
                <div className="meta">
                  {slide.tag && (
                    <p>
                      <span className="tag">{slide.tag}</span>
                    </p>
                  )}
                </div>
                <h1>
                  {slide.title}
                  <br />
                  <strong>{slide.titleBold}</strong>
                </h1>
                {slide.link && (
                  <a
                    className="btn btn-dark btn-icon"
                    href={slide.link.url}
                    dangerouslySetInnerHTML={{
                      __html:
                        slide.link.title + '<i class="btb bt-arrow-right"></i>',
                    }}
                  />
                )}
              </div>
            ))}
          </Slider>
        )}
        {circle.text && (
          <div className="slider--circle">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="crcl">
                    <p dangerouslySetInnerHTML={{ __html: circle.text }} />
                    <a
                      href={circle.link.url}
                      target={circle.link.target}
                      rel={circle.link.target ? 'noopener' : ''}
                    >
                      <strong>{circle.link.title}</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

HeroSlider.propTypes = {
  isMD: PropTypes.bool,
  slider: PropTypes.object.isRequired,
}

export default HeroSlider
