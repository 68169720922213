import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const durationFormat = seconds => {
  var hrs = ~~(seconds / 3600)
  var mins = ~~((seconds % 3600) / 60)
  var secs = ~~seconds % 60

  var duration = ''

  if (hrs > 0) {
    duration += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  duration += '' + mins + ':' + (secs < 10 ? '0' : '')
  duration += '' + secs
  return duration
}

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPlaying: false,
      duration: false,
    }

    this.ref = React.createRef()

    this.loadedMetaData = this.loadedMetaData.bind(this)
  }

  componentDidMount() {
    this.ref.current.addEventListener('loadedmetadata', this.loadedMetaData)
  }

  loadedMetaData() {
    const seconds = this.ref.current.duration
    this.setState({ duration: durationFormat(seconds) })
  }

  render() {
    const { title, url, thumbnail, watermark } = this.props
    const { isPlaying } = this.state

    return (
      <div className="dhsv_video__container">
        <div className="dhsv_video">
          <video ref={this.ref} controls playsInline>
            <source src={url} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          {!isPlaying && (
            <div
              className="ctnwrapper"
              style={{ backgroundImage: `url(${thumbnail})` }}
              onClick={() => {
                this.ref.current.play()
                this.setState({ isPlaying: true })
              }}
            >
              <div className="play-icon" />
            </div>
          )}
        </div>

        {!isPlaying &&
          watermark && (
            <img src={watermark} className="dhsv_video__watermark" />
          )}

        <p className="dhsv_video__caption">{title}</p>
      </div>
    )
  }
}

Video.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  thumbnail: PropTypes.string,
  watermark: PropTypes.string,
}

export default Video
