import React from 'react'
import PropTypes from 'prop-types'
import { PanelGroup, Panel } from 'react-bootstrap'
import l10n from '../../shared/l10n'

const { siteCode, single: { sHelpdesk } } = l10n

const accId = (Math.random() * (99999999 - 10000000 + 1)) << 0

// function b64EncodeUnicode(str) {
//   return btoa(
//     encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
//       return String.fromCharCode(parseInt(p1, 16))
//     })
//   )
// }

function simulateClick(e) {
  console.log(e) //eslint-disable-line

  const evt = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  })

  const canceled = !e.dispatchEvent(evt)
  console.log(canceled) //eslint-disable-line
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    const panelId = window.location.hash
      ? window.location.hash.substring(1)
      : 'nohash'

    this.state = {
      startKey: this.props.items.findIndex(item => {
        return item.id === panelId
      }),
    }

    this.accRef = React.createRef()

    this.accHashChanged = this.accHashChanged.bind(this)
  }

  componentDidMount() {
    window.onhashchange = this.accHashChanged
  }

  accHashChanged() {
    const self = this
    const { startKey } = this.state

    const panelId = window.location.hash
      ? window.location.hash.substring(1)
      : ''

    const panelIndex = self.props.items.findIndex(item => {
      return item.id === panelId
    })

    if ((panelIndex || panelIndex === 0) && startKey !== panelIndex) {
      setTimeout(function() {
        simulateClick(
          self.accRef.current.children[0].children[panelIndex].querySelector(
            'a'
          )
        )
      }, 500)
    }
  }

  render() {
    const { items, furthertext, multiopen, base64, type } = this.props
    const { startKey } = this.state

    return (
      <div
        className={`dhsv_accordion ${furthertext ? 'furthertext' : ''}`}
        ref={this.accRef}
      >
        <PanelGroup
          id={'dhsv_accordion--' + accId}
          accordion={!multiopen}
          defaultActiveKey={startKey >= 0 ? startKey : Number.MAX_SAFE_INTEGER}
        >
          {items.map((item, i) => (
            <Panel id={item.id} eventKey={i} key={i}>
              <Panel.Heading>
                <Panel.Title toggle>
                  <div dangerouslySetInnerHTML={{ __html: item.title }} />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                {type === 'job' ? (
                  <div>
                    <p>
                      {item.data.name}
                      <br />
                      {item.data.street}
                      <br />
                      {item.data.zip} {item.data.city}
                    </p>
                    <a
                      href={item.data.maplink.url}
                      target="_blank"
                      rel="noopener"
                    >
                      {item.data.maplink.title}
                      <i className="bts bt-map-marker" />
                    </a>
                    {item.data.phone && (
                      <div className="meta">
                        <span>Tel {siteCode === 'nl' ? 'receptie' : ''}: </span>
                        <a href={`tel:${item.data.phone}`}>{item.data.phone}</a>
                      </div>
                    )}
                    {item.data.phoneHelpdesk && (
                      <div className="meta">
                        <span>Tel {sHelpdesk}: </span>
                        <a href={`tel:${item.data.phoneHelpdesk}`}>
                          {item.data.phoneHelpdesk}
                        </a>
                      </div>
                    )}
                    {item.data.fax && (
                      <div className="meta">
                        <span>Fax: </span>
                        <a href={`fax:${item.data.fax}`}>{item.data.fax}</a>
                      </div>
                    )}
                    {item.data.mail && (
                      <div className="meta">
                        <span>Mail: </span>
                        <a href={`mailto:${item.data.mail}`}>
                          {item.data.mail}
                        </a>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: base64
                        ? b64DecodeUnicode(item.content)
                        : item.content,
                    }}
                  />
                )}
              </Panel.Body>
            </Panel>
          ))}
        </PanelGroup>
      </div>
    )
  }
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
  furthertext: PropTypes.bool,
  firstopen: PropTypes.bool,
  multiopen: PropTypes.bool,
  base64: PropTypes.bool,
  type: PropTypes.string,
}

Accordion.defaultProps = {
  type: '',
}

export default Accordion
