import React from 'react'
import TestimonialBG from './testimonial_bg.svg'
import TestimonialSmallBG from './testimonial-small_bg.svg'
import PropTypes from 'prop-types'
import l10n from '../../shared/l10n'

const { common: { readMore } } = l10n

class DhsvTestimonialItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { row, data } = this.props
    const svg = ['magazin_2_spalten_left', 'magazin_2_spalten_right'].includes(
      row
    )
      ? atob(TestimonialBG.replace('data:image/svg+xml;base64,', ''))
      : atob(TestimonialSmallBG.replace('data:image/svg+xml;base64,', ''))

    return (
      <div
        className={
          'blog-item testimonial red' +
          (!['magazin_2_spalten_left', 'magazin_2_spalten_right'].includes(row)
            ? ' small'
            : '')
        }
      >
        <div className="inner">
          <div
            className="image"
            style={{
              backgroundImage: data.post_thumbnail
                ? 'url(' + data.post_thumbnail.large + ')'
                : null,
            }}
          />
          <div className="bgwrapper">
            <div className="bg" dangerouslySetInnerHTML={{ __html: svg }} />
          </div>
          <div className="meta">
            <blockquote>{data.zitat}</blockquote>
            <span className="subline">{data.subline}</span>
            {data.link &&
              data.link.url !== '' && (
                <div className="more">
                  <a href={data.link.url}>
                    {data.link.title ? data.link.title : readMore}
                  </a>
                </div>
              )}
          </div>
        </div>
      </div>
    )
  }
}

DhsvTestimonialItem.propTypes = {
  row: PropTypes.string,
  data: PropTypes.object,
}

export default DhsvTestimonialItem
