import React from 'react'
import PropTypes from 'prop-types'
import Teaser from './Teaser'
import './styles.scss'

class ListblockTeaser extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeBlock: 0 }
  }

  render() {
    const { link, posts } = this.props
    const { activeBlock } = this.state

    return (
      <div className={`dhsv_listblock_teaser`}>
        <div className="row">
          <div className="col">
            <div className="listcon">
              {posts.map((post, i) => (
                <div
                  onClick={() => this.setState({ activeBlock: i })}
                  key={i}
                  className={activeBlock === i ? 'active' : ''}
                >
                  <p>
                    <strong>{post.title}</strong>
                    <br />
                    {post.subline}
                  </p>
                </div>
              ))}
            </div>
            <div className="btncon">
              <a href={link.url} className="btn btn-dark btn-icon">
                {link.title} <i className="btb bt-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <Teaser data={posts[activeBlock]} />
          </div>
        </div>
      </div>
    )
  }
}

ListblockTeaser.propTypes = {
  link: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired,
}

export default ListblockTeaser
