import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'

const Modal = ({ training, module, closeButton }, ref) => {
  const bookEvent = event => {
    // fill fields, set page 2 and open form
    //console.log(training) //eslint-disable-line
    //console.log(module.id) //eslint-disable-line
    //console.log(event.date + ' | ' + event.address) //eslint-disable-line

    const $modal = $('.dhsv_vc_akademie_formular_modal')
    const form_id = 4
    const $gform = $('#gform_' + form_id)
    const $gform_footer = $gform.find('#gform_next_button_' + form_id + '_1')
    if ($gform.length) {
      const $training = $('#input_' + form_id + '_3')
      const $module = $('#input_' + form_id + '_4')
      const $event = $('#input_' + form_id + '_5')

      $module.prop('disabled', false)
      $event.prop('disabled', false)

      $training.val(training).change()
      $training[0].sumo.reload()
      $module.val(module.id).change()
      $event.val(event.id).change()

      $gform_footer.click()

      $modal.addClass('open')
    }
  }

  return (
    <div className="dhsv_akademie_training_modal" ref={ref}>
      {closeButton}
      <div>
        <img src={module.icon} />
        <div className="ctn">
          <p className="big red">{module.title}</p>
          <p>{module.text}</p>
        </div>
        <div className="trainer">
          <div>
            <p>Trainer</p>
            <p>{module.trainerName}</p>
          </div>
          <div>
            <img src={module.trainerImage} />
          </div>
        </div>
        <div className="events">
          <p>Termine</p>
          {module.events.map((event, i) => (
            <div key={i}>
              <p>{event.date}</p>
              <p>{event.address}</p>
              <p onClick={() => bookEvent(event)}>buchen</p>
            </div>
          ))}
        </div>
        <p className="big red price">Preis: {module.price}</p>
      </div>
    </div>
  )
}

Modal.propTypes = {
  ref: PropTypes.object,
  training: PropTypes.string,
  module: PropTypes.object,
  closeButton: PropTypes.node,
}

export default React.forwardRef(Modal)
