import { createSelector } from 'reselect'
import map from 'ramda/es/map'
import lensPath from 'ramda/es/lensPath'
import over from 'ramda/es/over'
import replace from 'ramda/es/replace'
import { buildQueryString } from '../../util/string'

const initialState = {
  data: [],
  isLoading: false,
  firstPageLoaded: false,
  error: null,
  nextPage: 1,
  totalPosts: 0,
  totalPages: 0,
  searchTerm: '',
}

const LOAD_POSTS = 'LOAD_POSTS'
const LOAD_POSTS_SUCCESS = 'LOAD_POSTS_SUCCESS'
const LOAD_POSTS_FAILURE = 'LOAD_POSTS_FAILURE'

export default (state = initialState, action = {}) => {
  const { data } = state
  const { type, payload = {} } = action

  switch (type) {
    case LOAD_POSTS:
      return {
        ...state,
        isLoading: true,
      }
    case LOAD_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.newPage === 1 ? payload.data : [...data, ...payload.data],
        totalPosts: payload.headers.get('x-wp-total'),
        totalPages: payload.headers.get('x-wp-totalpages'),
        nextPage: payload.newPage + 1,
        firstPageLoaded: true,
        searchTerm: payload.searchTerm,
      }
    case LOAD_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    default:
      return state
  }
}

export const loadPosts = ({
  postsPerPage = 10,
  searchTerm = '',
  taxType,
  taxID,
  featured = null,
  exclude = [],
  teaser,
}) => (dispatch, getState) => {
  const { nextPage: page, searchTerm: lastSearchTerm } = getState()
  const newPage = lastSearchTerm === searchTerm ? page : 1

  const query = buildQueryString({
    per_page: postsPerPage,
    search: searchTerm,
    page: newPage,
    featured: featured,
    hideFeatured: teaser || featured ? 0 : 1,
    [taxType]: taxID,
    exclude: exclude.length > 1 ? exclude : false,
  })
  console.log(query) //eslint-disable-line

  dispatch({ type: LOAD_POSTS, payload: { newPage, postsPerPage, searchTerm } })

  return fetch(`/wp-json/wp/v2/magazine?${query}`)
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          payload: {
            data: json,
            headers: response.headers,
            searchTerm,
            newPage,
          },
          type: LOAD_POSTS_SUCCESS,
        })

        return json
      })
    })
    .catch(error => {
      dispatch({
        payload: {
          error: error,
        },
        type: LOAD_POSTS_FAILURE,
      })
    })
}

export const loadTax = ({ type }) => dispatch => {
  const query = buildQueryString({
    hide_empty: true,
  })

  dispatch({ type: LOAD_POSTS, payload: {} })

  return fetch(`/wp-json/wp/v2/${type}/?${query}`)
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          payload: { data: json, headers: response.headers },
          type: LOAD_POSTS_SUCCESS,
        })

        return json
      })
    })
    .catch(error => {
      dispatch({
        payload: {
          error: error,
        },
        type: LOAD_POSTS_FAILURE,
      })
    })
}

export const sHasError = createSelector(s => s.error, error => !!error)

export const sHasNextPage = createSelector(
  s => s.totalPages,
  s => s.nextPage,
  (totalPages, nextPage) => nextPage <= totalPages
)

const sSearchTerm = ({ searchTerm }) => searchTerm

const sSearchTermHighlighter = createSelector(sSearchTerm, term =>
  replace(
    new RegExp(term, 'ig'),
    match => `<span class="search-term">${match}</span>`
  )
)

const titleLens = lensPath(['title', 'rendered'])

export const sPosts = createSelector(
  s => s.data,
  sSearchTermHighlighter,
  (items, searchTermHighlighter) =>
    map(over(titleLens, searchTermHighlighter))(items)
)

const sTitleTemplate = (_, { titleTemplate }) => titleTemplate
const sSiteTitle = (_, { sSiteTitle }) => sSiteTitle

export const sPageTitle = createSelector(
  sTitleTemplate,
  sSearchTerm,
  sSiteTitle,
  (titleTemplate, searchTerm, siteTitle) =>
    titleTemplate
      .replace('%%searchphrase%%', searchTerm)
      .replace('%%sitename%%', siteTitle)
      .replace(/%%[^%]%%/g, '')
)
