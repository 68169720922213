import React from 'react'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import DhsvBlogItem from './blogItem'
import LoadingIndicator from './loading'
import reducer, { loadPosts } from './state'

class DhsvBlogFeaturedPosts extends React.Component {
  constructor(props) {
    super(props)
    this.state = reducer()
    this.dispatch = this.dispatch.bind(this)
    this.loadPosts = this.loadPosts.bind(this)
  }

  dispatch(action) {
    if (typeof action === 'function') {
      action(this.dispatch, () => this.state)
    } else {
      this.setState(prevState => reducer(prevState, action))
    }
  }

  loadPosts() {
    const postsPerPage = 3
    const featured = 1
    this.dispatch(loadPosts({ postsPerPage, featured }))
  }

  componentDidMount() {
    // Make the Slider scroll effect
    this.loadPosts()
  }

  render() {
    window.flkty = this.flkty

    const { itemStyle, data, firstPageLoaded } = this.state
    const { readMore } = this.props

    const flickityOptions = {
      cellSelector: '.blog-item',
      draggable: data.length > 1 ? true : false,
      pageDots: data.length > 1 ? true : false,
      prevNextButtons: data.length > 1 ? true : false,
      contain: true,
      freeScroll: false,
      cellAlign: 'left',
    }

    return data.length > 0 ? (
      firstPageLoaded ? (
        <div className="featured_wrapper">
          <Flickity
            className="featured-posts"
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            flickityRef={c => (this.flkty = c)}
            reloadOnUpdate
          >
            {data.map((item, index) => (
              <DhsvBlogItem
                key={index}
                index={index}
                item={item}
                itemStyle={itemStyle}
                readMore={readMore}
              />
            ))}
          </Flickity>
        </div>
      ) : (
        <LoadingIndicator />
      )
    ) : null
  }
}

DhsvBlogFeaturedPosts.propTypes = {
  isMD: PropTypes.bool,
  readMore: PropTypes.string,
  title: PropTypes.string,
}

export default DhsvBlogFeaturedPosts
