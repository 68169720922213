import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import l10n from '../../shared/l10n'

const { single: { sMesse, sStandort, sBereich, sZeitraum } } = l10n

class FairEvents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { posts } = this.props

    return (
      <div className={`dhsv_fair_events`}>
        <table>
          <thead>
            <tr>
              <th>
                <span>{sMesse}</span>
              </th>
              <th>
                <span>{sStandort}</span>
              </th>
              <th>
                <span>{sBereich}</span>
              </th>
              <th>
                <span>{sZeitraum}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {posts.map((p, i) => (
              <tr key={i}>
                <td>
                  <a target="_blank" rel="noopener" href={p.link}>
                    {p.title}
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noopener" href={p.link}>
                    {p.location}
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noopener" href={p.link}>
                    {p.area}
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noopener" href={p.link}>
                    {p.dateStart + (p.dateEnd ? ' - ' + p.dateEnd : '')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

FairEvents.propTypes = {
  isMD: PropTypes.bool,
  posts: PropTypes.array.isRequired,
}

export default FairEvents
