import $ from 'jquery'

$(document).ready(dhsv_vc_subsidiaries())

function dhsv_vc_subsidiaries() {
  $('.dhsv_vc_subsidiaries').each(function() {
    /* $(this)
      .find('.listcon ul li')
      .on('click tap', function() {
        const $e = $(this)
        const $parent = $(this).parent()

        $parent.scrollLeft($parent.scrollLeft() + $e.position().left)
      }) */
  })
}
