function dhsv_vc_empty_space() {
  $('.vc_empty_space').each(function(idx, emptySpace) {
    const $emptySpace = $(emptySpace)
    const currentBreakpoint = window.bootstrapToolkit.current()
    /* eslint-disable no-console */
    console.log('Current Breakpoint: ' + currentBreakpoint)

    if (currentBreakpoint == 'xs' || currentBreakpoint == 'sm') {
      var breakpoint = $emptySpace.data('sm')
      $emptySpace.css('height', breakpoint)
    }

    if (currentBreakpoint == 'md') {
      var breakpoint_tablet = $emptySpace.data('md')
      $emptySpace.css('height', breakpoint_tablet)
    }

    /*
    if (currentBreakpoint) {
      var breakpoint = $emptySpace.data(currentBreakpoint)
      console.log('Breakpoint: ' + breakpoint)
        ? $emptySpace.data(currentBreakpoint)
        : $emptySpace.data('lg')

      $emptySpace.css('height', breakpoint)
    }
    */
  })
}
$(document).ready(dhsv_vc_empty_space)

$(window).resize(dhsv_vc_empty_space)
