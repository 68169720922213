// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_emptySpace.js'; import './autoload/_matchHeight.js'; import './autoload/_polyfills.js'; import './autoload/_sumoselect.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/akademie_formular/main.js'; import '../vc_elements/akademie_training/main.js'; import '../vc_elements/calculator/main.js'; import '../vc_elements/career_stories/main.js'; import '../vc_elements/contact_person/main.js'; import '../vc_elements/expandable_content/main.js'; import '../vc_elements/expandable_teaserbox/main.js'; import '../vc_elements/fact_list/main.js'; import '../vc_elements/fair_events/main.js'; import '../vc_elements/grid_photo/main.js'; import '../vc_elements/grid/main.js'; import '../vc_elements/icon_blocks/main.js'; import '../vc_elements/image_ctn_slider/main.js'; import '../vc_elements/job_prescreen/main.js'; import '../vc_elements/karrierebereich_video/main.js'; import '../vc_elements/link_list/main.js'; import '../vc_elements/listblock_teaser/main.js'; import '../vc_elements/locations/main.js'; import '../vc_elements/magazin_slider/main.js'; import '../vc_elements/magazin/main.js'; import '../vc_elements/media/main.js'; import '../vc_elements/page_anchors/main.js'; import '../vc_elements/press_grid_react/main.js'; import '../vc_elements/product_list/main.js'; import '../vc_elements/services_list_akademie/main.js'; import '../vc_elements/services_list/main.js'; import '../vc_elements/shop_ch/main.js'; import '../vc_elements/subsidiaries/main.js'; import '../vc_elements/teaserbox_akademie/main.js'; import '../vc_elements/teaserbox/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/training_program/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
