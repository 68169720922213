import { connect } from 'react-redux'
import Component from './component'
import PropTypes from 'prop-types'
import { sIsMD } from '../../redux/modules/browser'

const WithData = connect(state => ({
  isMD: sIsMD(state),
}))(Component)

WithData.propTypes = {
  isMD: PropTypes.bool,
}

export default WithData
