// import $ from 'jquery'
import 'packery/dist/packery.pkgd.min'

$(window).load(function() {
  $('.press-masonry').each(function(index, el) {
    $(el).packery({
      layoutMode: 'packery',
      itemSelector: '.press-masonry__item',
      gutter: '.gutter-sizer',
    })
  })
})
