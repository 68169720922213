import { createResponsiveStateReducer } from 'redux-responsive'

export default createResponsiveStateReducer({
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: 1919,
})

export const sIsMD = state => state.browser.lessThan.lg
