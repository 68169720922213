import React from 'react'
import PropTypes from 'prop-types'
//import { BrowserRouter as Router } from 'react-router-dom'
import reducer, { loadPosts } from './state'
import DhsvBlogFeaturedPosts from './featuredPosts'
import DhsvBlogPostGrid from './postGrid'
import LoadingIndicator from './loading'
import './style.scss'
import DhsvBlogFilterBar from './filterBar'

class DhsvBlog extends React.Component {
  constructor(props) {
    super(props)
    this.state = reducer()
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.dispatch = this.dispatch.bind(this)
    this.loadPosts = this.loadPosts.bind(this)
    this.hasMore = this.hasMore.bind(this)
    //Routes
    this.blogHome = this.blogHome.bind(this)
  }

  dispatch(action) {
    if (typeof action === 'function') {
      action(this.dispatch, () => this.state)
    } else {
      this.setState(prevState => reducer(prevState, action))
    }
  }

  loadPosts(newSearchTerm = undefined) {
    const { postsPerPage, current, exclude, teaser } = this.props
    const { searchTerm } = this.state
    const taxType = current.type
    const taxID = current.id
    const excludeItems =
      ['magazine_category', 'magazine_topic'].includes(current.type) ||
      (newSearchTerm !== undefined && newSearchTerm !== '') ||
      (searchTerm !== '' && newSearchTerm !== '')
        ? []
        : exclude
    //console.log(typeof newSearchTerm) //eslint-disable-line
    //console.log(searchTerm) //eslint-disable-line
    //console.log(newSearchTerm) //eslint-disable-line
    this.dispatch(
      loadPosts({
        postsPerPage,
        searchTerm: newSearchTerm !== undefined ? newSearchTerm : searchTerm,
        taxType,
        taxID,
        exclude: excludeItems,
        teaser,
      })
    )
  }

  componentDidMount() {
    this.loadPosts()
  }

  handleSearchTermChange(event) {
    this.loadPosts(event.target.value.toLowerCase())
  }

  loadMore() {
    this.loadPosts()
  }

  hasMore() {
    const { totalPages, nextPage } = this.state
    return totalPages > nextPage - 1
  }

  //Routes

  blogHome() {
    const {
      strings,
      current,
      startGrid,
      teaser,
      teaserButton,
      logo,
      isMD,
    } = this.props
    const { data, firstPageLoaded, isLoading, searchTerm } = this.state
    const hasMore = this.hasMore()

    return (
      <div className="row">
        <div className="col">
          {current.type !== 'blog' && searchTerm === '' && current.desc ? (
            <div className="tax-desc">
              <h2>{current.name}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: current.desc }}
              />
            </div>
          ) : null}
          {!teaser && current.type === 'blog' && searchTerm === '' ? (
            <DhsvBlogFeaturedPosts
              readMore={strings.readMore}
              title={strings.featuredTitle}
            />
          ) : null}
          <div className="post-wrapper">
            {!teaser ? (
              <DhsvBlogFilterBar
                isMD={isMD}
                strings={strings}
                handleSearchTermChange={this.handleSearchTermChange}
                currentID={current.id}
                tax={[
                  {
                    type: 'magazine_category',
                    title: strings.catTitle,
                  },
                ]}
                logo={logo}
              />
            ) : null}
            {isLoading && !firstPageLoaded ? (
              <LoadingIndicator />
            ) : (
              <DhsvBlogPostGrid
                posts={data}
                readMore={strings.readMore}
                loadMore={this.loadMore}
                hasMore={hasMore}
                startGrid={searchTerm ? [] : startGrid}
                teaser={teaser}
                current={current}
                isMD={isMD}
              />
            )}
          </div>
          {teaser ? (
            <div style={{ textAlign: 'center', margin: '30px 0 0' }}>
              <a href={teaserButton.link} className="button large">
                {teaserButton.title}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  render() {
    const { isMD } = this.props
    return (
      <div
        className={'dhsv_blog' + (isMD ? ' mobile' : '')}
        onClick={this.handleClick}
      >
        {this.blogHome()}
      </div>
    )
  }
}

DhsvBlog.propTypes = {
  isMD: PropTypes.bool,
  teaser: PropTypes.bool,
  teaserButton: PropTypes.object,
  postsPerPage: PropTypes.number,
  startGrid: PropTypes.array,
  strings: PropTypes.object,
  current: PropTypes.object,
  exclude: PropTypes.array,
  logo: PropTypes.string,
}

export default DhsvBlog
