import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import Dropdown from './Dropdown'
import Chip from './Chip'
import Teaser from './Teaser'
import TeaserPress from './TeaserPress'
import TeaserPhoto from './TeaserPhoto'
import filter from 'ramda/es/filter'
import l10n from '../../shared/l10n'

const {
  common: { loadMore, deleteAllFilters },
  single: { sThema, sJahr },
} = l10n

const componentClassname = 'dhsv_grid'

const isFilterActive = filters =>
  Object.keys(filters).reduce((acc, key) => acc || !!filters[key].length, false)

const initialFilterState = {
  kategorie: [],
  jahr: [],
}

class Grid extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: initialFilterState,
      loadcount: 10,
    }
  }

  componentDidMount() {
    //
  }

  mkOnChange(filterName) {
    return values => {
      const filters = { ...this.state.filters, [filterName]: values }
      this.setState({
        filters,
        showAll: isFilterActive(filters),
      })
    }
  }

  mkFilterChips(filterName) {
    const filters = this.state.filters
    return (
      <Fragment>
        {filters[filterName].map((activeFilter, i) => {
          const filterLabel = activeFilter
          return (
            <Chip
              key={i}
              label={filterLabel}
              onClick={() =>
                this.setState({
                  filters: {
                    ...filters,
                    [filterName]: filters[filterName].filter(
                      filter => filter !== activeFilter
                    ),
                  },
                })
              }
            />
          )
        })}
      </Fragment>
    )
  }

  render() {
    const { type, featured, posts, kategorien, jahre } = this.props
    const { filters, loadcount } = this.state
    const filterActive = isFilterActive(filters)

    const currentPosts = filter(post => {
      const kategorieRes =
        filters.kategorie.length === 0 ||
        post.kategorien.some(k => filters.kategorie.includes(k))

      const jahrRes =
        filters.jahr.length === 0 || filters.jahr.includes(post.jahr)

      return kategorieRes && jahrRes
    })(posts)

    return (
      <div className={componentClassname + ' -' + type}>
        <div className="-filter-dropdowns">
          <Dropdown
            label={sThema}
            onChange={this.mkOnChange('kategorie')}
            options={kategorien}
            value={filters.kategorie}
          />
          <Dropdown
            label={sJahr}
            onChange={this.mkOnChange('jahr')}
            options={jahre}
            value={filters.jahr}
          />
        </div>
        <div className="-filter-chips">
          {filterActive && (
            <Chip
              label={deleteAllFilters}
              onClick={() => this.setState({ filters: initialFilterState })}
              all
            />
          )}
          {this.mkFilterChips('kategorie')}
          {this.mkFilterChips('jahr')}
        </div>
        <div className="-griddle row">
          {currentPosts
            .slice(
              0,
              currentPosts.length < loadcount ? currentPosts.length : loadcount
            )
            .map((post, i) => (
              <div
                key={i}
                className={`col-12 col-sm-6 ${
                  !filterActive && featured && i === 0
                    ? '-featured'
                    : 'col-md-4 col-lg-3'
                }`}
              >
                {type === 'post' && (
                  <Teaser
                    featured={!filterActive && featured && i === 0}
                    post={post}
                  />
                )}
                {type === 'press' && (
                  <TeaserPress
                    featured={!filterActive && featured && i === 0}
                    post={post}
                  />
                )}
                {type === 'photo' && (
                  <TeaserPhoto
                    featured={!filterActive && featured && i === 0}
                    post={post}
                  />
                )}
              </div>
            ))}
        </div>
        {currentPosts.length > loadcount && (
          <div className="buttons">
            <button
              className="button"
              onClick={() =>
                this.setState(prevState => ({
                  loadcount: prevState.loadcount + 10,
                }))
              }
            >
              {loadMore}
            </button>
          </div>
        )}
      </div>
    )
  }
}

Grid.propTypes = {
  type: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  posts: PropTypes.array.isRequired,
  kategorien: PropTypes.array.isRequired,
  jahre: PropTypes.array.isRequired,
}

export default Grid
