import $ from 'jquery'

$(document).ready(dhsv_vc_calculator())

function dhsv_vc_calculator() {
  $(document).on('gform_post_render', function(event, form_id, current_page) {
    if (form_id === 6) {
      const $calculator = $('.dhsv_vc_calculator')
      $calculator.attr('data-currentpage', current_page)
      console.log($calculator) //eslint-disable-line
    }
  })

  $('#gform_6 .gfield_radio input[type="radio"]').on('change', function() {
    $(this)
      .closest('.gform_page')
      .find('.gform_page_footer .gform_next_button.button')
      .click()
  })

  $(document).on('gform_page_loaded', function(event, form_id) {
    if (form_id === 6) {
      $('#gform_6 .gfield_radio input[type="radio"]').on('change', function() {
        $(this)
          .closest('.gform_page')
          .find('.gform_page_footer .gform_next_button.button')
          .click()
      })
    }
  })
}
