import React from 'react'
import PropTypes from 'prop-types'
import l10n from '../../shared/l10n'

const { common: { learnMore } } = l10n

class Block extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { post } = this.props

    return (
      <div>
        <div
          className="-img"
          style={{ backgroundImage: 'url(' + post.image + ')' }}
        />
        <div className="-ctn">
          <div className="row">
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <p>
                <strong>{post.title}</strong>
              </p>
              <p dangerouslySetInnerHTML={{ __html: post.text }} />
              {post.link && (
                <a
                  href={post.link}
                  target="_blank"
                  rel="noopener"
                  className="btn btn-dark btn-icon"
                >
                  {learnMore}
                  <i className="btb bt-arrow-right" />
                </a>
              )}
            </div>
            <div className="col-12 col-lg-5 order-0 order-lg-1">
              <ul>
                {post.informations.map((info, i) => (
                  <li key={i}>
                    <span>{info.title}</span>
                    <span>{info.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Block.propTypes = {
  post: PropTypes.object.isRequired,
}

export default Block
