const HtmlNodes = html => {
  if (html) {
    var tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return Array.from(tempDiv.children).map(element => {
      const type = element.tagName

      return {
        type,
        content: element.innerHTML,
      }
    })
  }
}

export default HtmlNodes
