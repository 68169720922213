$(document).ready(function() {
  $(
    '.expandable-teaserbox--standard .expandable-teaserbox__title, .expandable-teaserbox--background .js-plus-icon'
  ).on('click', function() {
    $(this)
      .toggleClass('expanded')
      .closest('.expandable-teaserbox')
      .find('.rollover_content')
      .stop()
      .slideToggle()
  })
})
