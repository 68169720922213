import React from 'react'
import PropTypes from 'prop-types'
import {
  Page,
  Text,
  View,
  Document,
  Font,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'
import HtmlNodes from './HtmlNodes'
import background from '../../../images/calc-pdf-bg.jpg'

Font.register({
  family: 'LL Sari Tab',
  src: require('../../../fonts/LLSariTab/LLSariTab.ttf'),
})
Font.register({
  family: 'Barlow',
  src: require('../../../fonts/Barlow/Barlow-Regular.ttf'),
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Barlow',
    backgroundColor: '#fff2e3',
  },

  pageDescription: {
    color: '#fff',
    position: 'absolute',
    left: '8.5%',
    top: '15%',
    width: '34%',
    fontSize: '10pt',
  },

  pageDescriptionText: {
    marginBottom: 10,
  },

  pageDescriptionH3: {
    marginBottom: 10,
    fontSize: '16pt',
  },

  revenues: {
    textAlign: 'center',
    top: '13%',
    left: '71%',
    width: '20%',
    position: 'absolute',
  },

  revenuesLabel: {
    position: 'absolute',
    top: '4.8%',
    left: '70.5%',
    fontSize: '10.1pt',
  },

  revenueItem: {
    color: '#fff',
    fontSize: '20pt',
    position: 'relative',
  },

  revenueItemAmount: {
    fontSize: '26pt',
  },

  cupPrice: {
    color: '#fff',
    fontSize: '42pt',
    position: 'absolute',
    top: '26%',
    left: '45%',
  },

  cupLabel: {
    position: 'absolute',
    left: '8.5%',
    top: '31.5%',
    fontSize: '10.1pt',
  },

  revenuePerCup: {
    color: '#f37327',
    fontSize: '42pt',
    position: 'absolute',
    top: '41.5%',
    left: '27.5%',
  },

  revenuePerCupLabel: {
    position: 'absolute',
    top: '49.8%',
    right: '37.3%',
    fontSize: '10.1pt',
  },

  costs: {
    color: '#fff',
    fontSize: '12pt',
    position: 'absolute',
    top: '55%',
    right: '54%',
  },

  costsLabel: {
    position: 'absolute',
    top: '66.35%',
    right: '37.3%',
    fontSize: '10.1pt',
  },

  goodsCostPerCup: {
    position: 'relative',
  },

  machinePerCup: {
    position: 'relative',
    top: '30pt',
    right: '5pt',
  },

  taxAmount: {
    position: 'relative',
    top: '60pt',
    right: '10pt',
  },

  marges: {
    position: 'absolute',
    color: '#acafb3',
    textAlign: 'center',
    top: '51%',
    left: '71.5%',
    fontSize: '12pt',
  },

  margesLabel: {
    position: 'absolute',
    top: '47%',
    left: '70.5%',
    fontSize: '10.1pt',
  },

  margesItem: {
    position: 'relative',
    marginBottom: '16pt',
    width: '20%',
  },

  margesItemAmount: {
    fontSize: '26pt',
    color: '#fff',
    float: 'right',
    display: 'inline',
  },

  margesItemAmountPrefix: {
    fontSize: '13pt',
    float: 'left',
    display: 'inline',
  },

  phone: {
    color: '#fff',
    position: 'absolute',
    bottom: '5.8%',
    left: '12.5%',
    fontSize: '14pt',
  },

  email: {
    color: '#fff',
    position: 'absolute',
    bottom: '8.2%',
    left: '12.5%',
    fontSize: '14pt',
  },

  note: {
    position: 'absolute',
    color: '#fff',
    width: '40%',
    bottom: '9.7%',
    left: '51%',
    fontSize: '8pt',
  },
})

// Create Document Component
const PdfPage = ({
  entryData,
  // marges,
  numbers,
  costs,
  note,
  content,
  phone,
  email,
}) => {
  const htmlNodes = HtmlNodes(content)
  console.log(htmlNodes) //eslint-disable-line

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={background} />
        <View style={styles.pageDescription}>
          {htmlNodes.map((node, i) => (
            <Text
              key={i}
              style={
                node.type === 'H3'
                  ? styles.pageDescriptionH3
                  : styles.pageDescriptionText
              }
            >
              {node.content}
            </Text>
          ))}
        </View>
        <Text style={styles.phone}>{phone}</Text>
        <Text style={styles.email}>{email}</Text>
        <View style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <Text style={styles.revenuesLabel}>
            Ertrag bei {entryData.cupsPerDay} Bechern pro Tag:
          </Text>
          <View style={styles.revenues}>
            <View style={styles.revenueItem}>
              <Text style={styles.revenueItemAmount}>
                ~{numbers.revenuePerYear}€
              </Text>
              <Text>/Jahr</Text>
            </View>
            <View style={[styles.revenueItem, { top: '28%' }]}>
              <Text style={styles.revenueItemAmount}>
                ~{numbers.revenuePerMonth}€
              </Text>
              <Text>/Monat</Text>
            </View>
            <View style={[styles.revenueItem, { top: '50%' }]}>
              <Text style={styles.revenueItemAmount}>
                ~{numbers.revenuePerDay}€
              </Text>
              <Text>/Tag</Text>
            </View>
          </View>

          <Text style={styles.cupLabel}>
            Durchschnittlicher Ertrag pro Becher:
          </Text>
          <Text style={styles.cupPrice}>{numbers.priceBrutto}€</Text>
          <Text style={styles.revenuePerCup}>{numbers.revenuePerCup}€</Text>
          <Text style={styles.revenuePerCupLabel}>Konzeptertrag</Text>

          <Text style={styles.costsLabel}>Konzeptkosten</Text>
          <View style={styles.costs}>
            <Text style={styles.goodsCostPerCup}>
              {numbers.goodsCostPerCup}€
            </Text>
            <Text style={styles.machinePerCup}>
              {entryData.rentMachine ? costs.machine.perCup : 0}€
            </Text>
            <Text style={styles.taxAmount}>{numbers.taxAmount}€</Text>
          </View>

          <Text style={styles.margesLabel}>Margen:</Text>
          <View style={styles.marges}>
            <View style={styles.margesItem}>
              <View>
                <Text style={styles.margesItemAmountPrefix}>ca.</Text>
                <Text style={styles.margesItemAmount}>80%</Text>
              </View>
              <Text className="label">Bruttomarge</Text>
              <Text className="clarification">(bei reinem Warenbezug)</Text>
            </View>
            <View style={styles.margesItem}>
              <View>
                <Text style={styles.margesItemAmountPrefix}>ca.</Text>
                <Text style={styles.margesItemAmount}>60%</Text>
              </View>
              <Text className="label">Bruttomarge</Text>
              <Text className="clarification">(inkl. Maschine)</Text>
            </View>
          </View>
          {note && <Text style={styles.note}>{note}</Text>}
        </View>
      </Page>
    </Document>
  )
}

PdfPage.propTypes = {
  entryData: PropTypes.object,
  marges: PropTypes.object,
  numbers: PropTypes.object,
  costs: PropTypes.object,
  note: PropTypes.string,
  content: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
}

export default PdfPage
